import { AccountContext } from "../contexts/AccountContext";
import React, { useContext, useEffect, useRef, useState } from "react";
import { osName, browserName, deviceType } from "react-device-detect";
import {
  BsFacebook,
  BsFillCartCheckFill,
  BsFillHeartFill,
  BsGoogle,
  BsShop,
  BsStarFill,
} from "react-icons/bs";
import _axios from "../axios";
import packageJson from "../../package.json"; // Adjust the path as necessary
import { CiUser } from "react-icons/ci";
import { HandleAuthForm } from "./HandleAuthForm";
import { IoMdClose } from "react-icons/io";
import { Link } from "react-router-dom";
import { IoWarning } from "react-icons/io5";
import buildLink from "../urls";
import useDeviceSize from "./useDeviceSize";
import { MdAvTimer, MdCardMembership, MdFeedback } from "react-icons/md";
import { ImLocation } from "react-icons/im";
import { HiLightBulb } from "react-icons/hi";
import { CurrencyContext } from "../contexts/CurrencyContext";
import { motion } from "framer-motion";
import {
  RiCoupon3Fill,
  RiErrorWarningLine,
  RiUserFollowLine,
} from "react-icons/ri";
import { RiLoader2Line } from "react-icons/ri";
import {
  FaAngleRight,
  FaCheckCircle,
  FaCreditCard,
  FaEdit,
  FaExclamation,
  FaEye,
  FaEyeSlash,
  FaMoneyBillWave,
  FaTicketAlt,
  FaTv,
  FaUserAlt,
  FaWallet,
} from "react-icons/fa";
// import { CurrencyContext } from "@/contexts/CurrencyContext";
import Cookies from "js-cookie";
import PointsLoader from "./PointsLoader";
import PointsLoaderWhite from "./PointsLoaderWhite";
import { useGoogleLogin } from "@react-oauth/google";
import Loader from "./Loader";
import FacebookLoginRender from "react-facebook-login/dist/facebook-login-render-props";
import { useNavigate } from "react-router-dom";

function TopAccountTest() {
  const path = "";
  const [width] = useDeviceSize();
  const [state, dispatch] = useContext(AccountContext);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const { openAuthForm, closeAuthForm, type, setType } = HandleAuthForm();
  const [loadingRegister, setLoadingRegister] = useState(false);
  const identifierRef = useRef(null);
  const authValueRef = useRef(null);
  const navigate = useNavigate();

  // sign up data ref
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const emailRef = useRef(null);
  const phoneRef = useRef(null);
  const passwordRef = useRef(null);
  const confirmPasswordRef = useRef(null);
  const [phoneVerified, setphoneVerified] = useState("");
  const newPasswordRef = useRef(null);
  const newConfirmPasswordRef = useRef(null);
  const [identifierToResetPass, setIdentifierToResetPass] = useState(null);
  const [socialLoading, setSocialLoading] = useState(false);
  const [stateLogin, setStateLogin] = useState({});
  const [stateLoginResult, setStateLoginResult] = useState({});
  const [updatePersonalInfo, setUpdatePersonalInfo] = useState(false);

  const [openAlertUpdateInfo, setOpenAlertUpdateInfo] = useState(false);
  const intervalRefRequiredInfo = useRef(null);
  // const [provider,setProvider] = useState(null)
  // const [phoneVerified,setPhoneVerified] = useState(null)
  const [phoneExist, setPhoneExist] = useState(false);
  const [openEyesPassword, setOpenEyesPassword] = useState({
    loginPassword: false,
    signupPassword: false,
    signupConfirmPassword: false,
    newPassword: false,
    confirmNewPassword: false,
  });
  const [error, setError] = useState(null);
  // const [successMessage, setSuccessMessge] = useState(null)
  const remindRef = useRef(null);
  const dataInformation = useContext(CurrencyContext);
  const [counterOtp, setCounterOtp] = useState(0);
  const intervalRefSms = useRef(null);

  const [showPassword, setShowPassword] = useState(false); // State for password visibility
  const menuVariants = {
    hidden: { opacity: 0, y: -10 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -10 },
  };

  const modalVariants = {
    hidden: { scale: 1, opacity: 1 },
    visible: { scale: 1, opacity: 1 },
    exit: { scale: 1, opacity: 1 },
  };

  const elementsVariant = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        opacity: { duration: 0.5 }, // Delay opacity appearance
      },
    },
    exit: {
      opacity: 0,

      opacity: { duration: 0.5 },
    },
  };

  const [phoneNotExist, setPhoneNotExist] = useState("");

  const submitrequiredinfo = async (e) => {
    e.preventDefault();
    if (passwordRef.current.value != "") {
      if (passwordRef.current.value != confirmPasswordRef.current.value) {
        handleSetError("Password do not match");
      } else {
        setLoadingRegister(true);

        try {
          const obj = {
            firstname: firstNameRef.current.value,
            lastname: lastNameRef.current.value,
            email: emailRef.current.value,
            new_password: passwordRef.current.value,
            confirm: confirmPasswordRef.current.value,
            edit_identification: 1,
          };

          const url = buildLink(
            "save_account",
            undefined,
            undefined,
            window.location.host
          );

          const resp = await _axios.put(url, obj);

          if (resp.data.success) {
            // console.log("Response111:", resp.data);
            setLoadingRegister(false);
            // dispatch({ type: "setisRegistrationFormOpen", payload: false });
            setUpdatePersonalInfo(false);
            checkLogin("login");
          } else {
            setError("Failed to save account information.");
            setLoadingRegister(false);
          }
        } catch (error) {
          console.error(
            "Error occurred during saving account information:",
            error
          );
          setError("Error occurred during saving account information.");
          setLoadingRegister(false);
        }
      }
    } else {
      handleSetError("Password can not be empty !");
    }
  };

  const [oldUserInfo, setOldUserInfo] = useState(null);

  const menuItems = [
    {
      to: "/account/profile",
      icon: <FaUserAlt className="text-d16" />,
      label: "Profile",
    },
    {
      to: "/account/addresses",
      icon: <i className="icon icon-location text-xl block w-7 ml-1"></i>,
      label: "Addresses",
    },

    {
      to: "/account/orders",
      icon: <FaMoneyBillWave className="text-d16" />,
      label: "Orders",
    },
    {
      to: "/account/memberShip",
      icon: <FaTv className="text-d16" />,
      label: "MemberShip",
    },
    {
      to: "/account/wallet",
      icon: <FaWallet className="text-d16" />,
      label: "Wallet",
    },
    {
      to: "/account/coupons",
      icon: <RiCoupon3Fill className="text-d16" />,
      label: "Coupons",
    },
    {
      to: "/account/checkin",
      icon: <FaCheckCircle className="text-d16" />,
      label: "Check In",
    },
    {
      to: "/account/buyagain",
      icon: <BsFillCartCheckFill className="text-d16" />,
      label: "Buy Again",
    },
    {
      to: "/account/wishlist",
      icon: <i className="icon icon-heart text-lg block w-8"></i>,
      label: "WishList",
    },
    {
      to: "/account/recentlyViewed",
      icon: <MdAvTimer className="text-d18" />,
      label: "Recently Viewed",
    },
    {
      to: "/account/reviewCenter",
      icon: <BsStarFill className="text-d16" />,
      label: "Review Center",
    },
    {
      to: "/account/feedback",
      icon: <MdFeedback className="text-d16" />,
      label: "Feedback",
    },
    {
      to: "/account/shops",
      icon: <BsShop className="text-d16" />,
      label: "Shops",
    },
   
  
  ];
  
  const menuRef = useRef(null);

  // Close menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowUserMenu(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setShowUserMenu]);

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  //   const router  = useRouter()
  const handleSetError = (error) => {
    setError(error);
    setTimeout(() => {
      setError(null);
    }, 3000);
  };

  // useEffect(() => {
  //   console.log(dataInformation);
  // }, []);

  const handleStartCountOtp = () => {
    intervalRefSms.current = 30;

    intervalRefSms.current = setInterval(() => {
      setCounterOtp((prevCounter) => {
        if (prevCounter === 1) {
          clearInterval(intervalRefSms.current);

          intervalRefSms.current = null;
          return 0;
        } else {
          return prevCounter - 1;
        }
      });
    }, 1000);
  };

  const loginWithEmail = async () => {
    if (
      identifierRef.current.value === "" ||
      authValueRef.current.value === ""
    ) {
      handleSetError("Required info is missing");
    } else {
      // console.log("Starting login process...");
      try {
        setLoadingRegister(true);

        // const location = await getLocation();
        const device_info = { user_agent: getDeviceInfo().loginChannel };
        const credentials = {
          device_info: JSON.stringify(device_info),
          version_number: packageJson.version,
          login_channel: width > 650 ? "web_desktop" : "web_mobile",
          latitude: "",
          longitude: "",
          identifier: identifierRef.current.value,
          type_login: "email",
          redirect: false,
          login_type: "default",
          password: authValueRef.current.value,
        };

        // console.log("Credentials for SignIn:", credentials);

        const response = await _axios.post(buildLink("newLogin"), credentials);
        // console.log("SignIn Response:", response);

        if (response.data.success) {
          setLoadingRegister(false);
          Cookies.set("login_type", credentials.type_login);
          checkLogin("login");
          closeAuthForm();
        } else {
          setLoadingRegister(false);
          handleSetError(response.data.errors[0].errorMsg);

          if (response.error === "404") {
            setType("signUp");
          } else {
            handleSetError(response.data.errors[0].errorMsg);
          }
        }
      } catch (err) {
        setLoadingRegister(false);
        console.error("Login Error:", err.message);
        handleSetError(err.message);
      }
    }
  };

  const getLocation = async () => {
    try {
      if (navigator.geolocation) {
        return new Promise((resolve) => {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              resolve({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
              });
            },
            () => {
              // If there's an error (e.g., permission denied), return default coordinates
              resolve({
                latitude: 0.0,
                longitude: 0.0,
              });
            }
          );
        });
      } else {
        // If geolocation is not supported, return default coordinates
        return {
          latitude: 0.0,
          longitude: 0.0,
        };
      }
    } catch (error) {
      // In case of any other error, return default coordinates
      return {
        latitude: 0.0,
        longitude: 0.0,
      };
    }
  };

  const getDeviceInfo = () => {
    return {
      loginChannel: osName + " - " + browserName,
    }; // osName provides the device's OS
  };

  const handleClickEyeButton = (type) => {
    setOpenEyesPassword((prevState) => ({
      ...prevState,
      [type]: !prevState[type], // toggles the current value
    }));
  };

  useEffect(() => {
    checkLogin("login");
  }, []);

  async function signup(e) {
    e.preventDefault();
    try {
      setLoadingRegister(true);

      // const location = await getLocation();
      const device_info = { user_agent: getDeviceInfo().loginChannel };

      const obj = {
        email: emailRef.current.value,
        password: passwordRef.current.value,
        confirm: confirmPasswordRef.current.value,
        firstname: firstNameRef.current.value,
        lastname: lastNameRef.current.value,
        telephone: phoneRef.current.value,
        redirect: false,
        device_info: JSON.stringify(device_info),
        type_login: "email",
        version_number: packageJson.version,
        login_channel: width > 650 ? "web_desktop" : "web_mobile",
        latitude: "",
          longitude: "",
        verified: true,
      };
      const response = await _axios.post(buildLink("register"), obj);
      // console.log("Response: ", response); // Log the response for debugging

      if (response.data.success) {
        checkLogin("register");
        setLoadingRegister(false);
        closeAuthForm()
      } else {
        // setErrorMessage(response.error); // Set the error message
        if (response.data.errors && response.data.errors.length > 0) {
          // Loop through all the errors and display the errorMsg
          response.data.errors.forEach(error => {
            handleSetError(error.errorMsg);  // This will set the error message in the UI
            console.log(error.errorMsg);  // You can log each error message to the console for debugging
          });
        }
        setLoadingRegister(false);
      }
    } catch (err) {
      handleSetError("something went wrong !");
    }
  }
  async function signupwithemail(e) {
    e.preventDefault();
    try {
      setLoadingRegister(true);

      // const location = await getLocation();
      const device_info = { user_agent: getDeviceInfo().loginChannel };

      const obj = {
        email: emailRef.current.value,
        password: passwordRef.current.value,
        confirm: confirmPasswordRef.current.value,
        firstname: firstNameRef.current.value,
        lastname: lastNameRef.current.value,
        redirect: false,
        device_info: JSON.stringify(device_info),
        type_login: "email",
        version_number: packageJson.version,
        login_channel: width > 650 ? "web_desktop" : "web_mobile",
        latitude: "",
          longitude: "",
        verified: true,
      };
      const response = await _axios.post(buildLink("register"), obj);
      // console.log("Response: ", response); // Log the response for debugging

      if (response.data.success) {
        checkLogin("register");
        setLoadingRegister(false);
        closeAuthForm();
      } else {
        // setErrorMessage(response.error); // Set the error message
        if (response.data.errors && response.data.errors.length > 0) {
          // Loop through all the errors and display the errorMsg
          response.data.errors.forEach(error => {
            handleSetError(error.errorMsg);  // This will set the error message in the UI
            console.log(error.errorMsg);  // You can log each error message to the console for debugging
          });
        }
        setLoadingRegister(false);
      }
    } catch (err) {
      handleSetError("something went wrong !");
    }
  }
  useEffect(() => {
    console.log(updatePersonalInfo);
    if (updatePersonalInfo) {
      if (window.location.pathname == "/account/ProfileTest") {
        // setUpdatePersonalInfo(false);
        setOpenAlertUpdateInfo(false);
        clearInterval(intervalRefRequiredInfo.current);
      } else {
        intervalRefRequiredInfo.current = setInterval(() => {
          setOpenAlertUpdateInfo(true);
        }, 15000);
      }
    } else {
      //   console.log("___________ b")
      // clearInterval(intervalRefRequiredInfo.current )
      // intervalRefRequiredInfo.current = null;
    }
    return () => {
      clearInterval(intervalRefRequiredInfo.current);
      intervalRefRequiredInfo.current = null;
    };
  }, [updatePersonalInfo, window.location.pathname]);

  async function validatePhoneNumberSignUp() {
    if (identifierRef.current.value === "") {
      handleSetError("Required info is missing");
      return;
    }
    setLoadingRegister(true);
    _axios
      .post(buildLink("verifyTelephoneCode"), {
        otpNumber: authValueRef.current.value,
      })
      .then((res) => {
        setLoadingRegister(false);
        if (res.data.success) {
          if (res.data.redirect == "login") {
            setType("loginPhone");
            handleSetError(
              "This number is already exist enter password to login"
            );
            setphoneVerified(identifierRef.current.value);
            setPhoneExist(true);
            setTimeout(() => {
              setPhoneExist(false);
            }, 10000);
          } else {
            setphoneVerified(identifierRef.current.value);
            setType("completeInfoSignup");
          }
        } else {
          handleSetError("something went wrong !");
        }
      });
  }

  async function logOut(e) {
    e.preventDefault();

    dispatch({ type: "setLoading", payload: true });
    // const location = await getLocation();

    setShowUserMenu(false);
    //remove next-auth session from cookie, and clear the jwt(session) obj.
    // await signOut({ redirect: false });
    //Logout from A
    const device_info = { user_agent: getDeviceInfo().loginChannel };
    const obj = {
      device_info: JSON.stringify(device_info),
      customer_id: state.userId,
      login_channel: width > 650 ? "web_desktop" : "web_mobile", // Assuming getDeviceInfo is a valid function
      version_number: packageJson.version,
      latitude: "",
      longitude: "",
      type_login: Cookies.get("login_type") || "",
      login_token:
        Cookies.get("login_type") == "google" ||
        Cookies.get("login_type") == "facebook"
          ? Cookies.get("login_token")
          : "",
    };

    const response = await _axios.post(
      buildLink("logout", undefined, undefined, window.config["site-url"]),
      obj
    );
    checkLogin("login");
    dispatch({ type: "setSeller", payload: false });
    Cookies.remove("seller_id");
    window.location.href = "/";
  }



  const checkLogin = (type) => { 
    

    try {
  
        _axios
        .get(buildLink("information", undefined, window.innerWidth))
        .then((response) => {
          const data = response.data.data;
         dispatch({ type: "setAnalyticsUrl", payload: data.analytics_url });
         dispatch({ type: "setFooterPaymentImages", payload: data.footer_payment_image });
        });
    
      _axios
        .get(
          buildLink(
            "newLogin",
            undefined,
            undefined,
            window.config["site-url"]
          ) +
            "&type=" +
            type
        )
        .then((response) => {
          if (response.data.success) {
            const data = response.data;
           
            if (data.customer_id > 0) {
              if (!data?.has_birthday) {
                let header = document.getElementById("headersticky");
                header.classList.remove("hide");
                dispatch({ type: "setopenRemindBirthday", payload: true });
                setTimeout(
                  () =>
                    dispatch({ type: "setopenRemindBirthday", payload: false }),
                  8000
                );
              }
              if (data.required_phone_number) {
                dispatch({ type: "setRequiredPhoneNumber", payload: true });
                setUpdatePersonalInfo(true);
              } else {
                dispatch({ type: "setRequiredPhoneNumber", payload: false });
                setUpdatePersonalInfo(false);
              }
              // setProvider(data.provider);
              dispatch({ type: "setLoged", payload: true });
              dispatch({ type: "setLoading", payload: false });
              dispatch({ type: "setUsername", payload: data.username });
              dispatch({ type: "setEmail", payload: data.email });
              dispatch({ type: "setFirstname", payload: data?.firstname });
              dispatch({ type: "setUserId", payload: data?.customer_id });
              dispatch({ type: "setLastname", payload: data?.lastname });
              if (
                data.seller_logged !== "0" &&
                data.seller_logged !== null &&
                data.seller_logged !== undefined
              ) {
                dispatch({ type: "setSeller", payload: true });
                Cookies.set("seller_id", data.seller_logged);
              }
            } else {
              dispatch({ type: "setLoged", payload: false });
            }
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  const sendOtpForgetPassword = async () => {
    try {
      setIdentifierToResetPass(identifierRef.current.value);
      setCounterOtp(30);
      handleStartCountOtp();

      const response = await _axios.post(buildLink("getOTPForetPassword"), {
        identifier: identifierRef.current.value,
      });
    } catch (err) {
      handleSetError(err.response.data.message);
    }
  };

  const resetPassword = () => {
    setLoadingRegister(true);
    _axios
      .post(buildLink("resetPassword"), {
        new_password: newPasswordRef.current.value,
        confirm_new_password: newConfirmPasswordRef.current.value,
      })
      .then((res) => {
        setLoadingRegister(false);
        if (res.data.success) {
          setType("loginPhone");
        }
      })
      .catch((err) => {
        handleSetError(err.response.data.message);
      });
  };

  const validateOTPForgetPassword = async () => {
    setLoadingRegister(true);
    _axios
      .post(buildLink("validateOTPForgetPassword"), {
        otpNumber: authValueRef.current.value,
      })
      .then((res) => {
        if (res.data.success) {
          setType("resetPassword");
        } else {
        }
        setLoadingRegister(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingRegister(false);
        handleSetError(err.response.data.message);
      });
  };

  const sendOtpForLoginTelephon = async () => {
    try {
      setCounterOtp(30);
      handleStartCountOtp();
      const response = await _axios.post(buildLink("newLogin"), {
        identifier: identifierRef.current.value,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const signUpWithPhone = async () => {
    if (identifierRef.current.value == "") {
      handleSetError("Phone number is required !");
    } else {
      try {
        setCounterOtp(30);
        handleStartCountOtp();
        const obj = {
          identifier: identifierRef.current.value,
        };
        const response = await _axios.post(
          buildLink("newLogin") + "&source_id=1",
          obj
        );
        if (response.data.success) {
        }
      } catch (err) {
        // handleSetError(err.message)
        console.log("_______");
        console.log(err);
      }
    }
  };

  const loginWithPhone = async () => {
    if (identifierRef.current.value == "" || authValueRef.current.value == "") {
      handleSetError("Require info are missing");
    } else {
      try {
        setLoadingRegister(true);
        // const location = await getLocation();
        const device_info = { user_agent: getDeviceInfo().loginChannel };
        const obj = {
          device_info: JSON.stringify(device_info),
          version_number: packageJson.version,
          login_channel: width > 650 ? "web_desktop" : "web_mobile",
          latitude: "",
          longitude: "",
          identifier: identifierRef.current.value,
          redirect: false,
          login_type: "default",
        };
        let response;
        if (type == "loginPhoneOtp") {
          obj.type_login = "otp";
          obj.otpNumber = authValueRef.current.value;
          response = await _axios.post(buildLink("loginByOTP"), obj);

          if (response.data.success == true) {
            setLoadingRegister(false);
            Cookies.set("login_type", obj.type_login);
            checkLogin("login");
            closeAuthForm();
          } else {
            setLoadingRegister(false);
            handleSetError(response.data.errors[0].errorMsg);
          }
        } else {
          obj.type_login = "telephone";
          obj.password = authValueRef.current.value;
          if (phoneVerified == obj.identifier) {
            obj.verifyTelephone = true;
          }
          response = await _axios.post(buildLink("newLogin"), obj);

          if (response.status === 200) {
            setLoadingRegister(false);
            Cookies.set("login_type", obj.type_login);
            checkLogin("login");
            closeAuthForm();
            handleSetError(response.data.errors[0].errorMsg);
          } else {
            setLoadingRegister(false);
            if (response.error == "404") {
              setType("signUp");
            } else {
              handleSetError(response.error);
            }
          }
        }
      } catch (err) {
        // handleSetError(err.message)
        console.log("_______");
        console.log(err);
      }
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async (credentialResponse) => {
      try {
        setSocialLoading(true);
        // const location = await getLocation();

        const device_info = { user_agent: getDeviceInfo().loginChannel };
        const obj = {
          device_info: JSON.stringify(device_info),
          email: "",
          login_channel: width > 650 ? "web_desktop" : "web_mobile",
          version_number: packageJson.version,
          provider: "google",
          social_access_token: credentialResponse.access_token,
          latitude: "",
          longitude: "",
        };

        const url = buildLink(
          "social",
          undefined,
          undefined,
          window.location.host
        );

        // Make the API call to send the data
        const resp = await _axios.post(url, obj);
        Cookies.set("login_type", "google");
        Cookies.set("login_token", credentialResponse.access_token);
        setSocialLoading(false);
        // Call the login check after successful API call
        closeAuthForm();
        checkLogin("login");
      } catch (error) {
        console.error("Error occurred during login:", error);
      }
    },
    onError: (error) => {
      console.error("Google login error:", error);
    },
  });

  useEffect(() => {
    if (
      Object.keys(stateLogin).length > 0 &&
      Object.keys(stateLoginResult).length > 0
    ) {
      // Fetch location and call successFB
      // getLocation()
      //   .then((location) => {
          successFB(stateLogin, stateLoginResult);//remove location param
        // })
        // .catch((error) => {
        //   console.error("Error fetching location:", error);
        //   // Handle location fetch error if needed
        // });
    }
  }, [stateLogin, stateLoginResult]);
  function successFB(response1, response, location) {
    setSocialLoading(true);
    const email = response?.email
      ? response?.email
      : `${response.id}@ishtari-mobile.com`;
    const device_info = { user_agent: getDeviceInfo().loginChannel };
    const obj = {
      device_info: JSON.stringify(device_info),
      email,
      login_channel: width > 650 ? "web_desktop" : "web_mobile",
      version_number: packageJson.version,
      provider: "facebook",
      social_access_token: response1.accessToken,
      latitude: "",
      longitude: "",
      id: response.id,
    };

    _axios
      .post(
        buildLink("social", undefined, undefined, window.location.host),
        obj
      )
      .then((resp) => {
        setSocialLoading(false);
        Cookies.set("login_type", "facebook");
        Cookies.set("login_token", response1.accessToken);
        checkLogin("login");
      })
      .catch((error) => {
        setSocialLoading(false);
        console.error("Error during Facebook login:", error);
      });
  }


  useEffect(() => {
    dispatch({ type: "setAdminLoading", payload: true });
    // 70 91 1870

    var adminToken = Cookies.get("ATDetails");

    if (
      typeof adminToken != typeof undefined
      // ||
      // window.location.host.startsWith("localhost")
    ) {
      dispatch({ type: "setAdminToken", payload: adminToken });
      dispatch({ type: "setAdmin", payload: true });
      dispatch({ type: "setAdminLoading", payload: false });
    } else {
      dispatch({ type: "setAdmin", payload: false });
      dispatch({ type: "setAdminLoading", payload: false });
    }
    dispatch({ type: "setLoading", payload: true });
    _axios
      .get(buildLink("login", undefined, undefined, window.config["site-url"]))
      .then((response) => {
        const data = response.data;

        if (data.customer_id > 0) {
          dispatch({ type: "setLoged", payload: true });
          dispatch({ type: "setUsername", payload: data.username });
          dispatch({ type: "setUserId", payload: data.customer_id });
          dispatch({ type: "setEmail", payload: data.email });
          dispatch({ type: "setFirstname", payload: data?.firstname });
          dispatch({ type: "setLastname", payload: data?.lastname });
        } else {
          dispatch({ type: "setLoged", payload: false });
        }
        dispatch({ type: "setLoading", payload: false });
        if (
          data.seller_logged !== "0" &&
          data.seller_logged !== null &&
          data.seller_logged !== undefined
        ) {
          dispatch({ type: "setSeller", payload: true });
          Cookies.set("seller_id", data.seller_logged);
        }
      });
  }, [dispatch]);

  useEffect(() => {
    if (state.openRemindBirthday) {
      function handleClickOutside(event) {
        if (remindRef.current && !remindRef.current.contains(event.target)) {
          dispatch({ type: "setopenRemindBirthday", payload: false });
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("scroll", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
        document.removeEventListener("scroll", handleClickOutside);
      };
    }
  }, [remindRef.current, state.openRemindBirthday, state.loged]);

  return (
    <div>
      {/* <AnimatePresence> */}
      {state.isRegistrationFormOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={`fixed inset-0 bg-dblackOverlay bg-opacity-50 z-40 transition-opacity 
          }`}
          onClick={closeAuthForm}
        />
      )}
      {/* </AnimatePresence> */}
      {/* <AnimatePresence> */}
      {state.isRegistrationFormOpen && (
        <motion.div
          variants={modalVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
          className={`fixed authentication-form  mx-auto my-auto overflow-hidden inset-0 bg-white rounded-lg  sm:w-[30rem] w-[90%]  max-md:min-h-[60vh] min-h-[40vh] h-fit   max-h-[90vh]  overflow-y-auto z-50 shadow-lg transform transition-all duration-300 ease-in-out
          }`}
        >
          <div
            className={`absolute  ${
              error ? " scale-0  rotate-0" : " scale-100 rotate-180"
            } transition-all right-4 z-50 top-4`}
          >
            <button
              onClick={closeAuthForm}
              className="p-1 border border-dplaceHolder w-fit h-fit bg-dgrey rounded-full z-10"
            >
              <IoMdClose className="text-3xl max-md:text-lg" />
            </button>
          </div>

          <h2
            className={` ${
              error ? " top-0" : " -top-11"
            } py-2  absolute  flex text-center justify-center  overflow-hidden transition-all w-full bg-dbase  gap-3 bg-opacity-20 text-dbase`}
          >
            <IoWarning className=" my-auto" />
            <span>{error}</span>
          </h2>

          {type == "default" && (
            <motion.div
              variants={elementsVariant}
              initial="hidden"
              animate="visible"
              exit="exit"
              className="gap-2 pt-10 relative text-center flex flex-col justify-center h-full"
            >
              {socialLoading && (
                <div className=" w-full h-full bg-dblackOverlay flex justify-center absolute inset-0 z-50">
                  <div className=" w-14 mx-auto my-auto">
                    <Loader />
                  </div>
                </div>
              )}
              <h2 className="text-4xl max-md:text-2xl font-extrabold text-dblackk">
                Log in to <span className="">Ishtari</span>
              </h2>

              <div className="flex flex-col mt-6 px-4 sm:px-16 gap-1">
                <button
                  onClick={() => setType("loginEmail")}
                  className="font-sans font-semibold text-[15px] rounded-lg px-3 flex items-center justify-center h-11 relative cursor-pointer mb-3 bg-white border border-dplaceHolder bg-opacity-10 hover:bg-opacity-20 hover:bg-dplaceHolder text-[#161823] whitespace-nowrap"
                >
                  <CiUser className="text-lg my-auto" />
                  <span className="whitespace-nowrap flex-nowrap w-full font-bold">
                    Use phone / email{" "}
                  </span>
                </button>

                {dataInformation && dataInformation.data.allow_login_by_fb && (
                  <FacebookLoginRender
                    appId={window.config["appId"]}
                    fields="name,email"
                    scope="public_profile,email"
                    isMobile={false}
                    onSuccess={(response) => {
                      setStateLogin(response);
                    }}
                    onFail={(error) => {
                      // console.log("Login Failed!", error);
                    }}
                    onProfileSuccess={(response) => {
                      setStateLoginResult(response);
                    }}
                    render={(renderProps) => (
                      <button
                        onClick={() => renderProps.onClick()}
                        className="font-sans font-semibold text-[15px] rounded-lg px-3 flex items-center justify-center h-11 relative cursor-pointer mb-3 bg-white border border-dplaceHolder bg-opacity-10 hover:bg-opacity-20 hover:bg-dplaceHolder text-[#161823] whitespace-nowrap"
                      >
                        <BsFacebook className="text-lg text-dblue my-auto" />
                        <span className="whitespace-nowrap flex-nowrap w-full font-bold">
                          Continue with Facebook
                        </span>
                      </button>
                    )}
                  />
                )}

                {dataInformation &&
                  dataInformation.data.allow_login_by_google && (
                    <button
                      onClick={() => googleLogin()}
                      className="font-sans font-semibold text-[15px] rounded-lg px-3 flex items-center justify-center h-11 relative cursor-pointer mb-3 bg-white border border-dplaceHolder bg-opacity-10 hover:bg-opacity-20 hover:bg-dplaceHolder text-[#161823] whitespace-nowrap"
                    >
                      <BsGoogle className="text-lg my-auto" />
                      <span className="whitespace-nowrap flex-nowrap w-full font-bold">
                        Continue with Google
                      </span>
                    </button>
                  )}
              </div>

              <div className="flex flex-col justify-end gap-3 w-full h-full">
                <p className="px-6 sm:px-20 text-xs text-center text-dlabelColor">
                  By continuing with an account located in{" "}
                  <strong className="font-extrabold text-dblackk">
                    Ghana
                  </strong>
                  , you agree to our Terms of Service and acknowledge that you
                  have read our{" "}
                  <Link
                    onClick={() => closeAuthForm()}
                    to={"/information/3"}
                    className="font-extrabold text-dblack"
                  >
                    Privacy Policy
                  </Link>
                  .
                </p>

                <div className="border-t w-full border-dplaceHolder py-6">
                  <h2 className="text-sm text-dlabelColor">
                    Don't you have an account?{" "}
           
                    {dataInformation &&
                  dataInformation.data.allow_login_by_otp_web && (
                      <button
                        onClick={() => setType("signUp")}
                        className="font-bold text-dbase"
                      >
                        Sign up
                      </button>
                   )}
                     {dataInformation &&
                  !dataInformation.data.allow_login_by_otp_web && (
                      <button
                        onClick={() => setType("SignUpEmail")}
                        className="font-bold text-dbase"
                      >
                        Sign up
                      </button>
                   )}
                  </h2>
                </div>
              </div>
            </motion.div>
          )}

          {(type == "loginPhone" || type == "loginPhoneOtp") && (
            <motion.div
              variants={elementsVariant}
              initial="hidden"
              animate="visible"
              exit="exit"
              className="gap-2 pt-10 text-center flex flex-col justify-center h-full"
            >
              <h2 className="text-4xl max-md:text-2xl font-extrabold text-dblackk">
                Log in
              </h2>
              {/* {error && <h2 className={` py-2 flex text-center justify-center relative overflow-hidden transition-all w-full bg-dbase  gap-3 bg-opacity-20 text-dbase`}><IoWarning className=" my-auto" /><span>{error}</span></h2>} */}
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  loginWithPhone();
                }}
                className="flex flex-col mt-6 px-4 sm:px-16 gap-1"
              >
                <div className=" flex flex-row w-full justify-between text-sm text-dgrey1 ">
                  <span className=" text-md text-dblack">Phone</span>
                  <button
                    type="button"
                    className=" hover:text-dblack hover:underline "
                    onClick={() => setType("loginEmail")}
                  >
                    Log in with email{" "}
                  </button>
                </div>

                <div
                  className={` ${
                    phoneExist
                      ? "  ring-4 bg-dgreyQtyProduct  ring-dPink  "
                      : "border border-[#d5d5d5]"
                  } transition-all  border-opacity-40   flex flex-row w-full bg-[#d5d5d5] bg-opacity-25 py-3  mb-3 rounded-md `}
                >
                  <div className="px-3 border-r whitespace-nowrap w-[40%] border-dlabelColor">
                    LBP +233{" "}
                  </div>
                  <input
                    defaultValue={phoneVerified}
                    type="number"
                    ref={identifierRef}
                    placeholder="Phone Number"
                    className={`  custom-cursor-color w-full h-full  px-4  bg-transparent outline-none border-none  `}
                  ></input>
                </div>

                {type == "loginPhoneOtp" && (
                  <div className="  border-opacity-40 border border-[#d5d5d5]  flex flex-row w-full bg-[#d5d5d5] bg-opacity-25   rounded-md ">
                    <input
                      ref={authValueRef}
                      placeholder="Enter 6-digit Code"
                      className="py-3 custom-cursor-color w-full h-full  px-4  bg-transparent outline-none border-none"
                    ></input>
                    <button
                      disabled={counterOtp > 0}
                      onClick={() => sendOtpForLoginTelephon()}
                      type="button"
                      className={` hover:bg-borderbottom px-3 py-2   border border-[#d5d5d5] shadow-dgrey1 w-[70%]  ${
                        counterOtp > 0 && "cursor-not-allowed"
                      }  rounded-e-md`}
                    >
                      {" "}
                      {counterOtp > 0 ? (
                        <span className=" text-sm ">
                          Wait{" "}
                          <span className=" text-dblue font-bold">
                            {counterOtp}
                          </span>
                          s{" "}
                        </span>
                      ) : (
                        " Send Code"
                      )}{" "}
                    </button>
                  </div>
                )}

                {type == "loginPhone" && (
                  <div className="  relative border-opacity-40 border border-[#d5d5d5]  flex flex-row w-full bg-[#d5d5d5] bg-opacity-25   rounded-md ">
                    <button
                      onClick={() => handleClickEyeButton("loginPhonePassword")}
                      type="button"
                      className=" absolute right-3 top-4"
                    >
                      {openEyesPassword.loginPhonePassword ? (
                        <FaEye />
                      ) : (
                        <FaEyeSlash />
                      )}
                    </button>
                    <input
                      ref={authValueRef}
                      placeholder="Password"
                      type={
                        openEyesPassword.loginPhonePassword
                          ? "text"
                          : "password"
                      }
                      className="py-3 custom-cursor-color w-full h-full  px-4  bg-transparent outline-none border-none"
                    ></input>
                  </div>
                )}

                {type == "loginPhoneOtp" && (
                  <div className=" flex flex-row w-full justify-between text-sm text-dgrey1 ">
                    <button
                      type="button"
                      className=" hover:text-dblack hover:underline "
                      onClick={() => setType("loginPhone")}
                    >
                      Log in with password
                    </button>
                  </div>
                )}

                {type == "loginPhone" && (
                  <div className=" flex flex-row w-full justify-start gap-5 text-sm text-dgrey1 ">

                  {dataInformation &&
                        dataInformation.data.enable_reset_password_by_otp && (
                    <button
                      type="button"
                      className=" hover:text-dblack hover:underline "
                      onClick={() =>
                        dataInformation &&
                        dataInformation.data.enable_reset_password_by_otp
                          ? setType("forgetpassword")
                          : setType("forgetpasswordemail")
                      }
                    >
                      Forget password
                    </button>
)}
                    
                    {dataInformation &&
                      dataInformation.data.allow_login_by_otp_web && (
                        <button
                          type="button"
                          className=" hover:text-dblack hover:underline "
                          onClick={() => setType("loginPhoneOtp")}
                        >
                          Log in with code
                        </button>
                      )}
                  </div>
                )}

                <button
                  disabled={loadingRegister}
                  type="submit"
                  className={` w-full    ${
                    loadingRegister ? "cursor-progress" : "py-3 "
                  }  rounded-md bg-dbase text-white mt-3 text-md font-bold`}
                >
                  {loadingRegister ? (
                    <div className=" h-10 relative">
                      <PointsLoaderWhite />
                    </div>
                  ) : (
                    "Log In"
                  )}
                </button>
              </form>

              <div className="flex flex-col justify-end gap-3 w-full h-full">
                <div className="border-t w-full border-dplaceHolder py-6">
                  <h2 className="text-sm text-dlabelColor">
                    Don't have an account ?{" "}
                    {dataInformation &&
                  dataInformation.data.allow_login_by_otp_web && (
                      <button
                        onClick={() => setType("signUp")}
                        className="font-bold text-dbase"
                      >
                        Sign up
                      </button>
                   )}
                     {dataInformation &&
                  !dataInformation.data.allow_login_by_otp_web && (
                      <button
                        onClick={() => setType("signUp")}
                        className="font-bold text-dbase"
                      >
                        Sign up
                      </button>
                   )}
                  </h2>
                </div>
              </div>
            </motion.div>
          )}

          {type == "loginEmail" && (
            <motion.div
              variants={elementsVariant}
              initial="hidden"
              animate="visible"
              exit="exit"
              className="gap-2 pt-10 text-center flex flex-col justify-center h-full"
            >
              <h2 className="text-4xl max-md:text-2xl font-extrabold text-dblackk">
                Log in
              </h2>
              {/* {error && <h2 className={` py-2 flex text-center justify-center relative overflow-hidden transition-all w-full bg-dbase  gap-3 bg-opacity-20 text-dbase`}><IoWarning className=" my-auto" /><span>{error}</span></h2>} */}
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  loginWithEmail();
                }}
                className="flex flex-col mt-6 px-4 sm:px-16 gap-1"
              >
                <div className=" flex flex-row w-full justify-between text-sm text-dgrey1 ">
                  <span className=" text-md text-dblack">Email</span>

                  <button
                    type="button"
                    className=" hover:text-dblack hover:underline "
                    onClick={() => setType("loginPhone")}
                  >
                    Log in with Phone number
                  </button>
                </div>

                <div className="   relative border-opacity-40 border border-[#d5d5d5]  flex flex-row w-full bg-[#d5d5d5] bg-opacity-25 py-3  rounded-md ">
                  <input
                    type="email"
                    ref={identifierRef}
                    placeholder="Email"
                    className=" custom-cursor-color w-full h-full  px-4  bg-transparent outline-none border-none"
                  ></input>
                </div>
                <div className=" relative border-opacity-40 border border-[#d5d5d5]  mt-3 flex flex-row w-full bg-[#d5d5d5] bg-opacity-25   rounded-md ">
                  <button
                    onClick={() => handleClickEyeButton("loginPassword")}
                    type="button"
                    className=" absolute right-3 top-4"
                  >
                    {openEyesPassword.loginPassword ? (
                      <FaEye />
                    ) : (
                      <FaEyeSlash />
                    )}
                  </button>
                  <input
                    ref={authValueRef}
                    placeholder="Password"
                    type={openEyesPassword.loginPassword ? "text" : "password"}
                    className="py-3 custom-cursor-color w-full h-full  px-4  bg-transparent outline-none border-none"
                  ></input>
                </div>
                <div className=" flex flex-row w-full justify-start gap-5 text-sm text-dgrey1 ">
                  {dataInformation && dataInformation.data.enable_reset_password_by_mail &&(
                  <button
                    type="button"
                    className=" hover:text-dblack hover:underline "
                    onClick={() =>
                      dataInformation &&
                      dataInformation.data.enable_reset_password_by_mail
                        ? setType("forgetpasswordemail")
                        : setType("forgetpassword")
                    }
                  >
                    Forget password
                  </button>
                  )}
                </div>
                <button
                  disabled={loadingRegister}
                  type="submit"
                  className={` w-full    ${
                    loadingRegister ? "cursor-progress" : "py-3 "
                  }  rounded-md bg-dbase text-white mt-3 text-md font-bold`}
                >
                  {loadingRegister ? (
                    <div className=" h-10 relative">
                      <PointsLoaderWhite />
                    </div>
                  ) : (
                    "Log In"
                  )}
                </button>
              </form>
              <div className="flex flex-col justify-end gap-3 w-full h-full">
                <div className="border-t w-full border-dplaceHolder py-6">
                  <h2 className="text-sm text-dlabelColor">
                    Don't have an account ?{" "}
                    {dataInformation &&
                  dataInformation.data.allow_login_by_otp_web && (
                      <button
                        onClick={() => setType("signUp")}
                        className="font-bold text-dbase"
                      >
                        Sign up
                      </button>
                   )}
                     {dataInformation &&
                  !dataInformation.data.allow_login_by_otp_web && (
                      <button
                        onClick={() => setType("signUp")}
                        className="font-bold text-dbase"
                      >
                        Sign up
                      </button>
                   )}
                  </h2>
                </div>
              </div>
            </motion.div>
          )}

          {type == "forgetpassword" && (
            <motion.div
              variants={elementsVariant}
              initial="hidden"
              animate="visible"
              exit="exit"
              className="gap-2 pt-10 text-center flex flex-col justify-center h-full"
            >
              <h2 className="text-4xl max-md:text-2xl font-extrabold text-dblackk">
                Forget Password
              </h2>

              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  validateOTPForgetPassword();
                }}
                className="flex flex-col mt-6 px-4 sm:px-16 gap-2"
              >
                <div className=" flex flex-row w-full justify-between text-sm text-dgrey1 ">
                  <span className=" text-md text-dblack">Phone</span>
                  {dataInformation && dataInformation.data.enable_reset_password_by_mail && (
                    <button
                      type="button"
                      className=" hover:text-dblack hover:underline "
                      onClick={() => setType("forgetpasswordemail")}
                    >
                      Reset with email
                    </button>
                  )}
                </div>

                <div className="  border-opacity-40 border border-[#d5d5d5]  flex flex-row w-full bg-[#d5d5d5] bg-opacity-25 py-3   rounded-md ">
                  <div className="px-3 border-r w-[40%] border-dlabelColor">
                    LBP +233{" "}
                  </div>
                  <input
                    type="number"
                    placeholder="Phone Number"
                    ref={identifierRef}
                    className=" custom-cursor-color w-full h-full  px-4  bg-transparent outline-none border-none"
                  ></input>
                </div>
                <div className="  border-opacity-40 border border-[#d5d5d5]  flex flex-row w-full bg-[#d5d5d5] bg-opacity-25   rounded-md ">
                  <input
                    ref={authValueRef}
                    placeholder="Enter 6-digit Code"
                    className="py-3 custom-cursor-color w-full h-full  px-4  bg-transparent outline-none border-none"
                  ></input>
                  <button
                    disabled={counterOtp > 0}
                    onClick={() => sendOtpForgetPassword()}
                    type="button"
                    className={` hover:bg-borderbottom px-3 py-2   border border-[#d5d5d5] shadow-dgrey1 w-[70%]  ${
                      counterOtp > 0 && "cursor-not-allowed"
                    }  rounded-e-md`}
                  >
                    {" "}
                    {counterOtp > 0 ? (
                      <span className=" text-sm ">
                        Wait{" "}
                        <span className=" text-dblue font-bold">
                          {counterOtp}
                        </span>
                        s{" "}
                      </span>
                    ) : (
                      " Send Code"
                    )}{" "}
                  </button>
                </div>
                <button
                  disabled={loadingRegister}
                  type="submit"
                  className={` w-full    ${
                    loadingRegister ? "cursor-progress" : "py-3 "
                  }  rounded-md bg-dbase text-white mt-3 text-md font-bold`}
                >
                  {loadingRegister ? (
                    <div className=" h-10 relative">
                      <PointsLoaderWhite />
                    </div>
                  ) : (
                    "Reset Password"
                  )}
                </button>
              </form>

              <div className="flex flex-col justify-end gap-3 w-full h-full">
                <div className="border-t w-full border-dplaceHolder py-6">
                  <h2 className="text-sm text-dlabelColor">
                    Don't have an account ?{" "}
                    {dataInformation &&
                  dataInformation.data.allow_login_by_otp_web && (
                      <button
                        onClick={() => setType("signUp")}
                        className="font-bold text-dbase"
                      >
                        Sign up
                      </button>
                   )}
                     {dataInformation &&
                  !dataInformation.data.allow_login_by_otp_web && (
                      <button
                        onClick={() => setType("signUp")}
                        className="font-bold text-dbase"
                      >
                        Sign up
                      </button>
                   )}
                  </h2>
                </div>
              </div>
            </motion.div>
          )}

          {type == "forgetpasswordemail" && (
            <motion.div
              variants={elementsVariant}
              initial="hidden"
              animate="visible"
              exit="exit"
              className="gap-2 pt-10 text-center flex flex-col justify-center h-full"
            >
              <h2 className="text-4xl max-md:text-2xl font-extrabold text-dblackk">
                Forget Password
              </h2>

              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  validateOTPForgetPassword();
                }}
                className="flex flex-col mt-6 px-4 sm:px-16 gap-2"
              >
                <div className=" flex flex-row w-full justify-between text-sm text-dgrey1 ">
                  <span className=" text-md text-dblack">Email</span>

                  {dataInformation && dataInformation.data.enable_reset_password_by_otp && (
                    <button
                      type="button"
                      className=" hover:text-dblack hover:underline"
                      onClick={() => setType("forgetpassword")}
                    >
                      Reset with phone
                    </button>
                  )}
                </div>

                <div className="   border-opacity-40 border border-[#d5d5d5]  flex flex-row w-full bg-[#d5d5d5] bg-opacity-25 py-3  rounded-md ">
                  <input
                    type="email"
                    ref={identifierRef}
                    placeholder="Enter your email"
                    className=" custom-cursor-color w-full h-full  px-4  bg-transparent outline-none border-none"
                  ></input>
                </div>
                <div className="  border-opacity-40 border border-[#d5d5d5]  flex flex-row w-full bg-[#d5d5d5] bg-opacity-25   rounded-md ">
                  <input
                    ref={authValueRef}
                    placeholder="Enter 6-digit Code"
                    className="py-3 custom-cursor-color w-full h-full  px-4  bg-transparent outline-none border-none"
                  ></input>
                  <button
                    disabled={counterOtp > 0}
                    onClick={() => sendOtpForgetPassword()}
                    type="button"
                    className={` hover:bg-borderbottom px-3 py-2   border border-[#d5d5d5] shadow-dgrey1 w-[70%]  ${
                      counterOtp > 0 && "cursor-not-allowed"
                    }  rounded-e-md`}
                  >
                    {" "}
                    {counterOtp > 0 ? (
                      <span className=" text-sm ">
                        Wait{" "}
                        <span className=" text-dblue font-bold">
                          {counterOtp}
                        </span>
                        s{" "}
                      </span>
                    ) : (
                      " Send Code"
                    )}{" "}
                  </button>
                </div>

                <button
                  disabled={loadingRegister}
                  type="submit"
                  className={` w-full    ${
                    loadingRegister ? "cursor-progress" : "py-3 "
                  }  rounded-md bg-dbase text-white mt-3 text-md font-bold`}
                >
                  {loadingRegister ? (
                    <div className=" h-10 relative">
                      <PointsLoaderWhite />
                    </div>
                  ) : (
                    "Reset Password"
                  )}
                </button>
              </form>

              <div className="flex flex-col justify-end gap-3 w-full h-full">
                <div className="border-t w-full border-dplaceHolder py-6">
                  <h2 className="text-sm text-dlabelColor">
                    Don't have an account ?{" "}
                    {dataInformation &&
                  dataInformation.data.allow_login_by_otp_web && (
                      <button
                        onClick={() => setType("signUp")}
                        className="font-bold text-dbase"
                      >
                        Sign up
                      </button>
                   )}
                     {dataInformation &&
                  !dataInformation.data.allow_login_by_otp_web && (
                      <button
                        onClick={() => setType("signUp")}
                        className="font-bold text-dbase"
                      >
                        Sign up
                      </button>
                   )}
                  </h2>
                </div>
              </div>
            </motion.div>
          )}

          {type == "resetPassword" && (
            <motion.div
              variants={elementsVariant}
              initial="hidden"
              animate="visible"
              exit="exit"
              className="gap-2 pt-10 text-center flex flex-col justify-center h-full"
            >
              <h2 className="text-4xl max-md:text-2xl font-extrabold text-dblackk">
                New Password
              </h2>

              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  resetPassword();
                }}
                className="flex flex-col mt-6 px-4 sm:px-16 gap-2"
              >
                <div className="  block w-full justify-center text-sm text-dgrey1 text-center ">
                  Enter your new password for ({" "}
                  <span className=" text-dbase font-bold">
                    {identifierToResetPass}
                  </span>{" "}
                  ) and confirm it .{" "}
                </div>

                <div className=" relative   border-opacity-40 border border-[#d5d5d5]  flex flex-row w-full bg-[#d5d5d5] bg-opacity-25 py-3  rounded-md ">
                  <button
                    onClick={() => handleClickEyeButton("newPassword")}
                    type="button"
                    className=" absolute right-3 top-4"
                  >
                    {openEyesPassword.newPassword ? <FaEye /> : <FaEyeSlash />}
                  </button>
                  <input
                    type={openEyesPassword.newPassword ? "text" : "password"}
                    ref={newPasswordRef}
                    placeholder="Enter new password"
                    className=" custom-cursor-color w-full h-full  px-4  bg-transparent outline-none border-none"
                  ></input>
                </div>
                <div className="  relative border-opacity-40 border border-[#d5d5d5]  flex flex-row w-full bg-[#d5d5d5] bg-opacity-25   rounded-md ">
                  <button
                    onClick={() => handleClickEyeButton("confirmNewPassword")}
                    type="button"
                    className=" absolute right-3 top-4"
                  >
                    {openEyesPassword.confirmNewPassword ? (
                      <FaEye />
                    ) : (
                      <FaEyeSlash />
                    )}
                  </button>
                  <input
                    type={
                      openEyesPassword.confirmNewPassword ? "text" : "password"
                    }
                    ref={newConfirmPasswordRef}
                    placeholder="Confirm new password"
                    className="py-3 custom-cursor-color w-full h-full  px-4  bg-transparent outline-none border-none"
                  ></input>
                </div>

                <button
                  disabled={loadingRegister}
                  type="submit"
                  className={` w-full    ${
                    loadingRegister ? "cursor-progress" : "py-3 "
                  }  rounded-md bg-dbase text-white mt-3 text-md font-bold`}
                >
                  {loadingRegister ? (
                    <div className=" h-10 relative">
                      <PointsLoaderWhite />
                    </div>
                  ) : (
                    "Reset"
                  )}
                </button>
              </form>

              <div className="flex flex-col justify-end gap-3 w-full h-full">
                <div className="border-t w-full border-dplaceHolder py-6">
                  <h2 className="text-sm text-dlabelColor">
                    Don't have an account ?{" "}
                    {dataInformation &&
                  dataInformation.data.allow_login_by_otp_web && (
                      <button
                        onClick={() => setType("signUp")}
                        className="font-bold text-dbase"
                      >
                        Sign up
                      </button>
                   )}
                     {dataInformation &&
                  !dataInformation.data.allow_login_by_otp_web && (
                      <button
                        onClick={() => setType("signUp")}
                        className="font-bold text-dbase"
                      >
                        Sign up
                      </button>
                   )}
                  </h2>
                </div>
              </div>
            </motion.div>
          )}

          {type == "signUp" && (
            <motion.div
              variants={elementsVariant}
              initial="hidden"
              animate="visible"
              exit="exit"
              className="gap-2 pt-10 text-center flex flex-col justify-center h-full"
            >
              <h2 className="text-4xl max-md:text-2xl font-extrabold text-dblackk">
                Sign up for Ishtari
              </h2>
              <p className=" text-center text-sm text-dlabelColor ">
                Enter your information . it's quick and easy
              </p>
              {/* {error && <h2 className={` py-2 flex text-center justify-center relative overflow-hidden transition-all w-full bg-dbase  gap-3 bg-opacity-20 text-dbase`}><IoWarning className=" my-auto" /><span>{error}</span></h2>} */}
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  validatePhoneNumberSignUp();
                }}
                className="flex flex-col mt-6 px-4 sm:px-16 gap-2"
              >
                <div className=" flex flex-row w-full justify-between text-sm text-dgrey1 ">
                  <span className=" text-md text-dblack">Phone</span>
                  <button
                    type="button"
                    className=" hover:text-dblack hover:underline "
                    onClick={() => setType("SignUpEmail")}
                  >
                    Sign Up with email{" "}
                  </button>
                </div>

                <div className="  border-opacity-40 border border-[#d5d5d5]  flex flex-row w-full bg-[#d5d5d5] bg-opacity-25 py-3   rounded-md ">
                  <div className="px-3 border-r w-[40%] border-dlabelColor">
                    LBP +233{" "}
                  </div>
                  <input
                    defaultValue={phoneNotExist}
                    type="tel"
                    placeholder="Phone Number"
                    ref={identifierRef}
                    className=" custom-cursor-color w-full h-full  px-4  bg-transparent outline-none border-none"
                  ></input>
                </div>
                <div className="  border-opacity-40 border border-[#d5d5d5]  flex flex-row w-full bg-[#d5d5d5] bg-opacity-25   rounded-md ">
                  <input
                    ref={authValueRef}
                    placeholder="Enter 6-digit Code"
                    className="py-3 custom-cursor-color w-full h-full  px-4  bg-transparent outline-none border-none"
                  ></input>
                  <button
                    disabled={counterOtp > 0}
                    onClick={() => signUpWithPhone()}
                    type="button"
                    className={` hover:bg-borderbottom px-3 py-2   border border-[#d5d5d5] shadow-dgrey1 w-[70%]  ${
                      counterOtp > 0 && "cursor-not-allowed"
                    }  rounded-e-md`}
                  >
                    {" "}
                    {counterOtp > 0 ? (
                      <span className=" text-sm ">
                        Wait{" "}
                        <span className=" text-dblue font-bold">
                          {counterOtp}
                        </span>
                        s{" "}
                      </span>
                    ) : (
                      " Send Code"
                    )}{" "}
                  </button>
                </div>
                <button
                  disabled={loadingRegister}
                  type="submit"
                  className={` w-full    ${
                    loadingRegister ? "cursor-progress" : "py-3 "
                  }  rounded-md bg-dbase text-white mt-3 text-md font-bold`}
                >
                  {loadingRegister ? (
                    <div className=" h-10 relative">
                      <PointsLoaderWhite />
                    </div>
                  ) : (
                    "Check"
                  )}
                </button>
              </form>

              <div className="flex flex-col justify-end gap-3 w-full h-full">
                <p className="px-6 sm:px-20 text-xs text-center text-dlabelColor">
                  By continuing with an account located in{" "}
                  <strong className="font-extrabold text-dblackk">
                    Ghana
                  </strong>
                  , you agree to our Terms of Service and acknowledge that you
                  have read our{" "}
                  <Link
                    onClick={() => closeAuthForm()}
                    to={"/information/3"}
                    className="font-extrabold text-dblack"
                  >
                    Privacy Policy
                  </Link>
                  .
                </p>

                <div className="border-t w-full border-dplaceHolder py-6">
                  <h2 className="text-sm text-dlabelColor">
                    Already have an account ?{" "}
                    <button
                      onClick={() => setType("default")}
                      className="font-bold text-dbase"
                    >
                      Log in
                    </button>
                  </h2>
                </div>
              </div>
            </motion.div>
          )}
           {type === "SignUpEmail" && (
  <motion.div
    variants={elementsVariant}
    initial="hidden"
    animate="visible"
    exit="exit"
    className="pt-10 text-center flex flex-col justify-center h-full max-w-md mx-auto px-4"
  >
    <h2 className="text-4xl sm:text-3xl font-extrabold text-dblackk mb-4">
      Sign up for Ishtari
    </h2>
    <p className="text-sm text-dlabelColor mb-6">
      Enter your information. It's quick and easy.
    </p>

    <div className="flex flex-row w-full justify-between text-sm text-dgrey1">
      <span className="text-md text-dblack">Email</span>
      {dataInformation && dataInformation.data.allow_login_by_otp_web && (
  <button
    type="button"
    className="hover:text-dblack hover:underline"
    onClick={() => setType("signUp")}
  >
    Sign up with Phone number
  </button>
)}
    </div>

    <form
      onSubmit={signupwithemail}
      className="flex flex-col mt-2 gap-3"
    >
      {/* First and Last Name Inputs */}
      <div className="flex gap-4">
        <div className="border border-[#d5d5d5] bg-[#d5d5d5] bg-opacity-25 py-3 rounded-md flex-1">
          <input
            ref={firstNameRef}
            placeholder="First name"
            className="w-full px-4 bg-transparent outline-none border-none"
          />
        </div>
        <div className="border border-[#d5d5d5] bg-[#d5d5d5] bg-opacity-25 py-3 rounded-md flex-1">
          <input
            ref={lastNameRef}
            placeholder="Last name"
            className="w-full px-4 bg-transparent outline-none border-none"
          />
        </div>
      </div>

      {/* Email Input */}
      <div className="border border-[#d5d5d5] bg-[#d5d5d5] bg-opacity-25 py-3 rounded-md">
        <input
          ref={emailRef}
          placeholder="Email"
          className="w-full px-4 bg-transparent outline-none border-none"
        />
      </div>

      {/* Password Input */}
      <div className="relative border border-[#d5d5d5] bg-[#d5d5d5] bg-opacity-25 py-3 rounded-md">
        <button
          onClick={() => handleClickEyeButton("signupPassword")}
          type="button"
          className="absolute right-3 top-4"
        >
          {openEyesPassword.signupPassword ? <FaEye /> : <FaEyeSlash />}
        </button>
        <input
          ref={passwordRef}
          type={openEyesPassword.signupPassword ? "text" : "password"}
          placeholder="Enter password"
          className="w-full px-4 bg-transparent outline-none border-none"
        />
      </div>

      {/* Confirm Password Input */}
      <div className="relative border border-[#d5d5d5] bg-[#d5d5d5] bg-opacity-25 py-3 rounded-md">
        <button
          onClick={() => handleClickEyeButton("signupConfirmPassword")}
          type="button"
          className="absolute right-3 top-4"
        >
          {openEyesPassword.signupConfirmPassword ? <FaEye /> : <FaEyeSlash />}
        </button>
        <input
          ref={confirmPasswordRef}
          type={openEyesPassword.signupConfirmPassword ? "text" : "password"}
          placeholder="Confirm password"
          className="w-full px-4 bg-transparent outline-none border-none"
        />
      </div>

      {/* Submit Button */}
      <button
        disabled={loadingRegister}
        type="submit"
        className={`w-full ${loadingRegister ? "cursor-progress" : "py-3"} rounded-md bg-dbase text-white text-md font-bold`}
      >
        {loadingRegister ? (
          <div className="h-10 relative">
            <PointsLoaderWhite />
          </div>
        ) : (
          "Sign Up"
        )}
      </button>
    </form>

    {/* Terms and Privacy Policy */}
    <div className="flex flex-col">
      <p className="px-3 py-2 text-xs text-dlabelColor">
        By continuing with an account located in{" "}
        <strong className="font-extrabold text-dblackk">Ghana</strong>, you agree to our Terms of Service and acknowledge that you have read our{" "}
        <Link
          onClick={closeAuthForm}
          href="/information/3"
          className="font-extrabold text-dblack"
        >
          Privacy Policy
        </Link>
        .
      </p>
    </div>
    <div className="flex flex-col justify-end gap-3 w-full h-full">
                <div className="border-t w-full border-dplaceHolder py-6">
                  <h2 className="text-sm text-dlabelColor">
                    Already have an account ?{" "}
                    <button
                      onClick={() => setType("default")}
                      className="font-bold text-dbase"
                    >
                      Log in
                    </button>
                  </h2>
                </div>
              </div>
  </motion.div>
)}
          {type == "completeInfoSignup" && (
            <motion.div
              variants={elementsVariant}
              initial="hidden"
              animate="visible"
              exit="exit"
              className="gap-2 pt-10 text-center flex flex-col justify-center h-full"
            >
              <h2 className="text-2xl font-extrabold text-dblackk">
                Enter your Information
              </h2>
              {/* <p className=" text-center text-sm text-dlabelColor ">Enter your information . it's quick and easy</p> */}
              {/* {error && <h2 className={` py-2 flex text-center justify-center relative overflow-hidden transition-all w-full bg-dbase  gap-3 bg-opacity-20 text-dbase`}><IoWarning className=" my-auto" /><span>{error}</span></h2>} */}

              <form
                onSubmit={(e) => {
                  signup(e);
                }}
                className="flex flex-col mt-6 px-4 sm:px-16 gap-2"
              >
                <div className="   relative border-opacity-40 border border-[#d5d5d5]  flex flex-row w-full bg-[#c4c4c4] bg-opacity-25 py-3  rounded-md ">
                  <input
                    readOnly
                    defaultValue={phoneVerified}
                    ref={phoneRef}
                    placeholder="Phone number"
                    className=" custom-cursor-color w-full h-full  px-4  bg-transparent outline-none border-none"
                  ></input>
                  <button
                    type="button"
                    onClick={() => setType("signUp")}
                    className=" absolute right-3 top-4"
                  >
                    <FaEdit />
                  </button>
                </div>
                <div className=" flex flex-row justify-center gap-2">
                  <div className="   border-opacity-40 border border-[#d5d5d5]  flex flex-row w-full bg-[#d5d5d5] bg-opacity-25 py-3  rounded-md ">
                    <input
                      ref={firstNameRef}
                      placeholder=" First name"
                      className=" custom-cursor-color w-full h-full  px-4  bg-transparent outline-none border-none"
                    ></input>
                  </div>

                  <div className="   border-opacity-40 border border-[#d5d5d5]  flex flex-row w-full bg-[#d5d5d5] bg-opacity-25 py-3  rounded-md ">
                    <input
                      ref={lastNameRef}
                      placeholder="Last name"
                      className=" custom-cursor-color w-full h-full  px-4  bg-transparent outline-none border-none"
                    ></input>
                  </div>
                </div>

                <div className="   border-opacity-40 border border-[#d5d5d5]  flex flex-row w-full bg-[#d5d5d5] bg-opacity-25 py-3  rounded-md ">
                  <input
                    ref={emailRef}
                    placeholder="Email"
                    className=" custom-cursor-color w-full h-full  px-4  bg-transparent outline-none border-none"
                  ></input>
                </div>

                <div className="  relative  border-opacity-40 border border-[#d5d5d5]  flex flex-row w-full bg-[#d5d5d5] bg-opacity-25 py-3  rounded-md ">
                  <button
                    onClick={() => handleClickEyeButton("signupPassword")}
                    type="button"
                    className=" absolute right-3 top-4"
                  >
                    {openEyesPassword.signupPassword ? (
                      <FaEye />
                    ) : (
                      <FaEyeSlash />
                    )}
                  </button>
                  <input
                    ref={passwordRef}
                    type={openEyesPassword.signupPassword ? "text" : "password"}
                    placeholder="Enter password"
                    className=" custom-cursor-color w-full h-full  px-4  bg-transparent outline-none border-none"
                  ></input>
                </div>

                <div className="   relative border-opacity-40 border border-[#d5d5d5]  flex flex-row w-full bg-[#d5d5d5] bg-opacity-25 py-3  rounded-md ">
                  <button
                    onClick={() =>
                      handleClickEyeButton("signupConfirmPassword")
                    }
                    type="button"
                    className=" absolute right-3 top-4"
                  >
                    {openEyesPassword.signupConfirmPassword ? (
                      <FaEye />
                    ) : (
                      <FaEyeSlash />
                    )}
                  </button>
                  <input
                    ref={confirmPasswordRef}
                    type={
                      openEyesPassword.signupConfirmPassword
                        ? "text"
                        : "password"
                    }
                    placeholder="Confirm password"
                    className=" custom-cursor-color w-full h-full  px-4  bg-transparent outline-none border-none"
                  ></input>
                </div>

                <button
                  disabled={loadingRegister}
                  type="submit"
                  className={` w-full    ${
                    loadingRegister ? "cursor-progress" : "py-3 "
                  }  rounded-md bg-dbase text-white mt-3 text-md font-bold`}
                >
                  {loadingRegister ? (
                    <div className=" h-10 relative">
                      <PointsLoaderWhite />
                    </div>
                  ) : (
                    "Sign Up"
                  )}
                </button>
              </form>

              <div className="flex flex-col justify-end gap-3 w-full h-full">
                <div className="border-t w-full border-dplaceHolder py-6">
                  <h2 className="text-sm text-dlabelColor">
                    Already have an account ?{" "}
                    <button
                      onClick={() => setType("default")}
                      className="font-bold text-dbase"
                    >
                      Log in
                    </button>
                  </h2>
                </div>
              </div>
            </motion.div>
          )}
        </motion.div>
      )}
      {/* </AnimatePresence> */}
      <div
        onClick={() => setOpenAlertUpdateInfo(false)}
        className={`fixed inset-0 bg-dblackOverlay bg-opacity-50 z-40 transition-opacity ${
          openAlertUpdateInfo ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
      ></div>
      <div
        className={` fixed  mx-auto my-auto overflow-hidden  inset-0 bg-white  rounded-lg  sm:w-[30rem] w-[90%]  h-fit max-h-[90vh]  overflow-y-auto z-50 shadow-lg transform transition-all duration-300 ease-in-out
       ${
         openAlertUpdateInfo
           ? "opacity-100 scale-100"
           : "opacity-0 scale-50 pointer-events-none"
       }`}
      >
        <div className=" px-3 flex py-2 flex-col h-fit w-full gap-3">
          <div className=" w-full flex flex-row  gap-3">
            <RiErrorWarningLine className=" my-auto text-6xl text-dbase" />{" "}
            <h2 className="text-xl my-auto">Require Info are missing</h2>
          </div>
          <h2 className=" text-dlabelColor text-sm  px-2">
            Go to
            <Link
              to={"/account/profileTest"}
              className=" text-dbase hover:underline "
            >
              {" "}
              profile
            </Link>{" "}
            put your number with your inforation and validate
          </h2>
          <div className=" w-full flex justify-end pt-7 ">
            {" "}
            <Link
              to={"/account/profileTest"}
              className="items-center rounded-lg w-fit px-4 py-2 bg-dbase text-white flex flex-row gap-2 "
            >
              <span>Go To Profile </span>
              <FaAngleRight className=" animate-bounceRL " />
            </Link>
          </div>
        </div>
      </div>

      <div
        className={`fixed inset-0 bg-dblackOverlay bg-opacity-50 z-40 transition-opacity ${
          updatePersonalInfo && navigate.pathname !== "/account/profileTest"
            ? "opacity-100"
            : "opacity-0 pointer-events-none"
        }`}
      ></div>

      <div
        className={` fixed  mx-auto my-auto overflow-hidden  inset-0 bg-white  rounded-lg  sm:w-[30rem] w-[90%]  h-fit max-h-[90vh]  overflow-y-auto z-50 shadow-lg transform transition-all duration-300 ease-in-out
       ${
         updatePersonalInfo && navigate.pathname !== "/account/profileTest"
           ? "opacity-100 scale-100"
           : "opacity-0 scale-50 pointer-events-none"
       }`}
      >
        <div className="gap-2 py-8 text-center flex flex-col relative justify-center h-full">
          <h2
            className={` ${
              error ? " top-0" : " -top-11"
            } py-2  absolute  flex text-center justify-center  overflow-hidden transition-all w-full bg-dbase  gap-3 bg-opacity-20 text-dbase`}
          >
            <IoWarning className=" my-auto" />
            <span>{error}</span>
          </h2>
          <h2 className="text-2xl font-extrabold text-dblackk flex flex-row gap-3 justify-center">
            {" "}
            <FaExclamation className=" my-auto text-2xl text-dbase " /> Require
            Info
          </h2>
          <p className=" text-center text-sm text-dlabelColor ">
            You should update and complete your account information for better
            experience.
          </p>
          {/* {error && <h2 className={` py-2 flex text-center justify-center relative overflow-hidden transition-all w-full bg-dbase  gap-3 bg-opacity-20 text-dbase`}><IoWarning className=" my-auto" /><span>{error}</span></h2>} */}

          <form
            onSubmit={submitrequiredinfo}
            className="flex flex-col mt-6 px-4 sm:px-16 gap-2"
          >
            {/* <div className="   relative border-opacity-40 border border-[#d5d5d5]  flex flex-row w-full bg-[#c4c4c4] bg-opacity-25 py-3  rounded-md ">
                <input defaultValue={phoneVerified} ref={phoneRef} placeholder="Phone number" readOnly className=" custom-cursor-color w-full h-full  px-4  bg-transparent outline-none border-none"></input>
                <button onClick={()=>navigate.push("/account/profile")}  className=" absolute right-3 top-4"><FaEdit/></button>
              </div> */}
            <div className=" flex flex-row justify-center gap-2">
              <div className="   border-opacity-40 border border-[#d5d5d5]  flex flex-row w-full bg-[#d5d5d5] bg-opacity-25 py-3  rounded-md ">
                <input
                  defaultValue={(oldUserInfo && oldUserInfo.firstname) || ""}
                  ref={firstNameRef}
                  placeholder=" First name"
                  className=" custom-cursor-color w-full h-full  px-4  bg-transparent outline-none border-none"
                ></input>
              </div>

              <div className="   border-opacity-40 border border-[#d5d5d5]  flex flex-row w-full bg-[#d5d5d5] bg-opacity-25 py-3  rounded-md ">
                <input
                  defaultValue={(oldUserInfo && oldUserInfo.lastname) || ""}
                  ref={lastNameRef}
                  placeholder="Last name"
                  className=" custom-cursor-color w-full h-full  px-4  bg-transparent outline-none border-none"
                ></input>
              </div>
            </div>

            <div className="   border-opacity-40 border border-[#d5d5d5]  flex flex-row w-full bg-[#d5d5d5] bg-opacity-25 py-3  rounded-md ">
              <input
                defaultValue={(oldUserInfo && oldUserInfo.email) || ""}
                ref={emailRef}
                placeholder="Email"
                className=" custom-cursor-color w-full h-full  px-4  bg-transparent outline-none border-none"
              ></input>
            </div>

            <div className="  relative  border-opacity-40 border border-[#d5d5d5]  flex flex-row w-full bg-[#d5d5d5] bg-opacity-25 py-3  rounded-md ">
              <button
                onClick={() => handleClickEyeButton("signupPassword")}
                type="button"
                className=" absolute right-3 top-4"
              >
                {openEyesPassword.signupPassword ? <FaEye /> : <FaEyeSlash />}
              </button>
              <input
                ref={passwordRef}
                type={openEyesPassword.signupPassword ? "text" : "password"}
                placeholder="Enter password"
                className=" custom-cursor-color w-full h-full  px-4  bg-transparent outline-none border-none"
              ></input>
            </div>

            <div className="   relative border-opacity-40 border border-[#d5d5d5]  flex flex-row w-full bg-[#d5d5d5] bg-opacity-25 py-3  rounded-md ">
              <button
                onClick={() => handleClickEyeButton("signupConfirmPassword")}
                type="button"
                className=" absolute right-3 top-4"
              >
                {openEyesPassword.signupConfirmPassword ? (
                  <FaEye />
                ) : (
                  <FaEyeSlash />
                )}
              </button>
              <input
                ref={confirmPasswordRef}
                type={
                  openEyesPassword.signupConfirmPassword ? "text" : "password"
                }
                placeholder="Confirm password"
                className=" custom-cursor-color w-full h-full  px-4  bg-transparent outline-none border-none"
              ></input>
            </div>

            <button
              disabled={loadingRegister}
              type="submit"
              className={` w-full    ${
                loadingRegister ? "cursor-progress" : "py-3 "
              }  rounded-md bg-dbase text-white mt-3 text-md font-bold`}
            >
              {loadingRegister ? (
                <div className=" h-10 relative">
                  <PointsLoaderWhite />
                </div>
              ) : (
                "Continue"
              )}
            </button>
          </form>

          <div className="flex flex-col justify-end gap-3 w-full h-full"></div>
        </div>
      </div>

      {state.loading && (
        <div className="  text-white  max-md:hidden flex justify-center group  gap-1  my-auto   px-5 border-r border-r-dplaceHolder ">
          {" "}
          <PointsLoaderWhite />
        </div>
      )}

      {!state.loged && !state.loading && (
        <button
          onClick={() => {
            openAuthForm("default");
          }}
          className=" text-white  max-md:hidden flex justify-center group  gap-1  px-5 border-r border-r-dplaceHolder"
        >
          <span className="   group-hover:opacity-75 transition-all  opacity-100 duration-200">
            Log In
          </span>
          <CiUser className=" my-auto  group-hover:opacity-75 transition-all  text-lg opacity-100 duration-200" />
        </button>
      )}

      {state.loged && !state.loading && (
        <div
          onClick={() => {}}
          className="
                
                
               max-md:hidden
                flex 
                items-start
                flex-col
                
                px-5
                relative 
                text-sm
                "
        >
          <div
            role="button"
            onClick={() => {
              setShowUserMenu(!showUserMenu);
            }}
            className="text-white cursor-pointer dropdown-toggle px-1 text-s leading-tight transition-all hover:opacity-60 whitespace-nowrap flex items-center"
          >
            <div className="flex flex-col">
              <p className="text-xs font-light">Welcome {state.username}</p>
              <span className="text-md flex flex-row gpa-2 font-extrabold">
                <p>My Account </p>
                <div
                  className={`dropdown-icon block max-md:hidden mt-1 ml-2 ${
                    showUserMenu ? "open" : ""
                  }`}
                >
                  <span></span>
                  <span></span>
                </div>
              </span>
            </div>
          </div>

          {showUserMenu && (
            <>
              {/* Backdrop */}
              <div
                className="fixed inset-0 bg-opacity-25 z-30"
                onClick={() => setShowUserMenu(false)}
              ></div>

              {/* Menu */}
              <motion.div
                ref={menuRef}
                className="absolute bg-white top-12 right-0 w-52 py-4 pb-0 z-40 shadow-xl text-dgrey1"
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={menuVariants}
                transition={{ duration: 0.2 }}
              >
                {menuItems.map((item, index) => (
                  <Link
                    key={index}
                    to={`${path}${item.to}`}
                    onClick={() => setShowUserMenu(false)}
                    className="py-2 flex items-center cursor-pointer hover:bg-dgrey1 hover:text-dbase delay-100 hover:bg-opacity-10 px-3"
                  >
                    <div className="w-2/12">{item.icon}</div>
                    {item.label}
                    <div className="w-2/12 group-hover:block hidden">
                      <img src={"/images/arrow-right.svg"} alt="arrow" />
                    </div>
                  </Link>
                ))}
                <p
                  onClick={(e) => logOut(e)}
                  className="text-center text-sm border-t border-dinputBorder cursor-pointer hover:bg-dbase hover:text-white py-3"
                >
                  Sign Out
                </p>
              </motion.div>
            </>
          )}
          <div
            ref={remindRef}
            onClick={() => navigate("/account/profileTest")}
            className={`  ${
              state.needUpdateInfo
                ? "   right-0 opacity-100 z-50"
                : " -right-96    opacity-0 -z-50"
            }  transition-all
           fixed mx-4 ${
             state.admin ? "top-[7.2rem]" : "top-20"
           } duration-300  cursor-pointer max-w-[330px]`}
          >
            <div className="flex  relative  gap-3 justify-center    py-4    w-full bg-dblack bg-opacity-70  shadow-lg container  rounded-lg">
              <h2 className="text-white text-sm leading-6  my-auto ">
                Update your profile information in account page.{" "}
                <button className=" bg-dbase px-2   py-1 rounded-full">
                  Account
                </button>
              </h2>
              <div className="tooltip-indicator"></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default TopAccountTest;
