import { LazyLoadImage } from "react-lazy-load-image-component";
import SingleProducts from "./SingleProduct";
import Slider from "react-slick";
import { Link, useLocation, useParams } from "react-router-dom";
import SliderPlaceholder from "../assets/images/placeholder_slideshow.png";
import ProductPlaceholder from "../assets/images/product_placeholder.png";
import { useWindowDimensions } from "./TopHeader";
import { path } from "../urls";
import { useContext, useRef, useState, useCallback } from "react";
import { AccountContext } from "../contexts/AccountContext";
import CustomArrows from "./CustomArrows";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

import { Pagination, Navigation, Autoplay } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { useProductContext } from "../contexts/ProductContext";
import DOMPurify from "dompurify";

function WidgetsLoop({ widget, likedData }) {
  let appliedStyles = null;
  const { width } = useWindowDimensions();
  const [dragging, setDragging] = useState(false);
  const [accountState] = useContext(AccountContext);
  const [showNext, setShowNext] = useState(false);
  const [showPrev, setShowPrev] = useState(false);
  const { setProductHolder } = useProductContext();
  const location = useLocation();
  const source_type =
    location.pathname === "/"
      ? "home"
      : location.pathname.startsWith("/category") ||
        location.pathname.includes("c=")
      ? "category"
      : location.pathname.startsWith("/seller") ||
        location.pathname.includes("s=")
      ? "seller"
      : location.pathname.startsWith("/manufacturer") ||
        location.pathname.includes("m=")
      ? "manufacturer"
      : location.pathname.startsWith("/latest")
      ? "new_arrival"
      : "home";
  const source_type_id = useParams().id;

  const types = {
    1: "product",
    2: "category",
    3: "manufacturer",
    4: "seller",
  };

  const swiperNavNextRef = useRef(null);
  const swiperNavPrevRef = useRef(null);

  const setting = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 4000,
    prevArrow: <CustomArrows direction={"l"} />,
    nextArrow: <CustomArrows direction={"r"} />,
  };
  const settingM = {
    dots: true,
    speed: 1000,
    slidesToShow: 1,
    swipeToSlide: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
    lazyLoad: true,
  };
  const productMobile = {
    dots: false,
    speed: 1000,
    slidesToShow:
      widget?.type === "banner"
        ? widget?.display === "carousel"
          ? 2.5
          : widget.column_number - 0.5
        : 2.5,
    swipeToSlide: false,
    slidesToScroll: 1,
    infinite: false,
    arrows: false,
    lazyLoad: true,
  };
  const productSetting = {
    speed: 200,
    slidesToShow:
      widget?.items?.length < 7
        ? widget?.items?.length
        : width <= 770
        ? 4
        : width <= 1100
        ? 4
        : 6,
    slidesToScroll: 6,
    infinite: true,
    prevArrow: <CustomPrevArrows direction={"l"} />,
    nextArrow: <CustomNextArrows direction={"r"} />,
  };

  function ToSeoUrl(url) {
    // make the url lowercase
    var encodedUrl = url.toString().toLowerCase();

    encodedUrl = encodedUrl.replace(/\s+&amp;\s+|\s+&gt;\s+/g, "-");

    // replace & with and
    encodedUrl = encodedUrl.split(/\&+/).join("-and-");

    // remove invalid characters
    encodedUrl = encodedUrl.split(/[^a-z0-9]/).join("-");

    // remove duplicates
    encodedUrl = encodedUrl.split(/-+/).join("-");

    // trim leading & trailing characters
    encodedUrl = encodedUrl.trim("-");

    encodedUrl = encodeURIComponent(encodedUrl);

    return encodedUrl;
  }

  const handleBeforeChange = useCallback(() => {
    setDragging(true);
  }, [setDragging]);

  const handleAfterChange = useCallback(() => {
    setDragging(false);
  }, [setDragging]);

  const handleOnItemClick = useCallback(
    (e) => {
      if (dragging) {
        // e.stopPropagation()
        e.preventDefault();
      }
    },
    [dragging]
  );

  function CustomPrevArrows({ direction, onClick, style, className }) {
    return (
      <div
        style={{ ...style, padding: "2px 5px" }}
        onClick={onClick}
        className="mySwiper"
      >
        <div className="swiper-button-prev"></div>
      </div>
    );
  }

  function CustomNextArrows({ direction, onClick, style, className }) {
    return (
      <div
        style={{ ...style, padding: "2px 5px" }}
        onClick={onClick}
        className="mySwiper"
      >
        <div className="swiper-button-next"></div>
      </div>
    );
  }

  const handleMouseEnter = () => {
    setShowNext(true);
  };

  const handleMouseLeave = () => {
    setShowNext(false);
  };

  const handleMouseEnterPrev = () => {
    setShowPrev(true);
  };

  const handleMouseLeavePrev = () => {
    setShowPrev(false);
  };

  var styles;
  if (width > 650) {
    styles = {
      backgroundColor:
        widget?.background_color !== "#000000"
          ? widget?.background_color
          : "white",
      paddingLeft:
        widget.padding_left !== "-1" ? widget.padding_left + "px" : "10px",
      paddingRight:
        widget.padding_right !== "-1" ? widget.padding_right + "px" : "10px",
      paddingBottom:
        widget.padding_bottom !== "-1" ? widget.padding_bottom + "px" : "10px",
      paddingTop:
        widget.padding_top !== "-1" ? widget.padding_top + "px" : "10px",
      marginLeft:
        widget.margin_left !== "-1" ? widget.margin_left + "px" : "1px",
      marginRight:
        widget.margin_right !== "-1" ? widget.margin_right + "px" : "1px",
      marginBottom:
        widget.margin_bottom !== "-1" ? widget.margin_bottom + "px" : "1px",
      marginTop: widget.margin_top !== "-1" ? widget.margin_top + "px" : "1px",
    };
  } else {
    styles = {
      backgroundColor:
        widget?.background_color !== "#000000"
          ? widget?.background_color
          : "white",
      paddingLeft:
        widget.padding_left !== "-1" ? widget.padding_left + "px" : "3px",
      paddingRight:
        widget.padding_right !== "-1" ? widget.padding_right + "px" : "3px",
      paddingBottom:
        widget.padding_bottom !== "-1" ? widget.padding_bottom + "px" : "3px",
      paddingTop:
        widget.padding_top !== "-1" ? widget.padding_top + "px" : "3px",
      marginLeft:
        widget.margin_left !== "-1" ? widget.margin_left + "px" : "1px",
      marginRight:
        widget.margin_right !== "-1" ? widget.margin_right + "px" : "1px",
      marginBottom:
        widget.margin_bottom !== "-1" ? widget.margin_bottom + "px" : "1px",
      marginTop: widget.margin_top !== "-1" ? widget.margin_top + "px" : "1px",
    };
  }

  appliedStyles = styles;

  return (
    <div
      key={widget.mobile_widget_id}
      style={appliedStyles}
      className="   max-md:-mx-2 overflow-hidden"
    >
      {widget.display === "carousel" && widget.view_title !== "0" ? (
        <div className="flex items-center justify-between mt-5 mb-3">
          {widget.view_title !== "0" && (
            <h1
              className="font-semibold text-xl"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(widget.title),
              }}
            />
          )}
          {widget.view_all !== "0" && (
            <div>
              {widget.type === "seller" ? (
                <Link
                  to={
                    widget.filters !== false && widget.filters !== ""
                      ? "/" +
                        widget.title
                          .replace(/\s+&amp;\s+|\s+&gt;\s+/g, "-")
                          .replace(/\s+/g, "-")
                          .replaceAll("/", "-")
                          .replace("%", "") +
                        "/s=" +
                        widget.type_id +
                        "?has_filter=true" +
                        (widget?.filters?.filter_categories
                          ? "&filter_categories=" +
                            widget?.filters?.filter_categories.map(
                              (fc) => fc.id
                            )
                          : "") +
                        (widget?.filters?.filter_manufacturers
                          ? "&filter_manufacturers=" +
                            widget?.filters?.filter_manufacturers.map(
                              (fm) => fm.id
                            )
                          : "") +
                        (widget?.filters?.filter_sellers
                          ? "&filter_sellers=" +
                            widget?.filters?.filter_sellers.map((fs) => fs.id)
                          : "") +
                        (widget?.filters?.filter_options
                          ? "&filter_options=" +
                            widget?.filters?.filter_options.map((fo) => fo.id)
                          : "")
                      : "/" +
                        //ToSeoUrl(widget.title)
                        widget.title
                          .replace(/\s+&amp;\s+|\s+&gt;\s+/g, "-")
                          .replace(/\s+/g, "-")
                          .replaceAll("/", "-")
                          .replace("%", "") +
                        "/s=" +
                        widget.type_id
                  }
                  state={{
                    banner_image_id: "",
                    source_type: source_type,
                    source_type_id: source_type_id ? source_type_id : "",
                  }}
                >
                  <h1 className="font-bold text-xs border px-2 py-1 cursor-pointer hover:opacity-80">
                    VIEW ALL
                  </h1>
                </Link>
              ) : widget.type === "manufacturer" ? (
                <Link
                  to={
                    widget.filters !== false && widget.filters !== ""
                      ? "/" +
                        widget.title
                          .replace(/\s+&amp;\s+|\s+&gt;\s+/g, "-")
                          .replace(/\s+/g, "-")
                          .replaceAll("/", "-")
                          .replace("%", "") +
                        "/m=" +
                        widget.type_id +
                        "?has_filter=true" +
                        (widget?.filters?.filter_categories
                          ? "&filter_categories=" +
                            widget?.filters?.filter_categories.map(
                              (fc) => fc.id
                            )
                          : "") +
                        (widget?.filters?.filter_manufacturers
                          ? "&filter_manufacturers=" +
                            widget?.filters?.filter_manufacturers.map(
                              (fm) => fm.id
                            )
                          : "") +
                        (widget?.filters?.filter_sellers
                          ? "&filter_sellers=" +
                            widget?.filters?.filter_sellers.map((fs) => fs.id)
                          : "") +
                        (widget?.filters?.filter_options
                          ? "&filter_options=" +
                            widget?.filters?.filter_options.map((fo) => fo.id)
                          : "")
                      : "/" +
                        // ToSeoUrl(widget.title)
                        widget.title
                          .replace(/\s+&amp;\s+|\s+&gt;\s+/g, "-")
                          .replace(/\s+/g, "-")
                          .replaceAll("/", "-")
                          .replace("%", "") +
                        "/m=" +
                        widget.type_id
                  }
                  state={{
                    banner_image_id: "",
                    source_type: source_type,
                    source_type_id: source_type_id ? source_type_id : "",
                  }}
                >
                  <h1 className="font-bold text-xs border px-2 py-1 cursor-pointer hover:opacity-80">
                    VIEW ALL
                  </h1>
                </Link>
              ) : (
                <Link
                  to={
                    widget.type === "new_arrival"
                      ? "/latest"
                      : widget.filters !== false && widget.filters !== ""
                      ? "/" +
                        widget.title
                          .replace(/\s+&amp;\s+|\s+&gt;\s+/g, "-")
                          .replace(/\s+/g, "-")
                          .replaceAll("/", "-")
                          .replace("%", "") +
                        "/c=" +
                        widget.type_id +
                        "?has_filter=true" +
                        (widget?.filters?.filter_categories
                          ? "&filter_categories=" +
                            widget?.filters?.filter_categories.map(
                              (fc) => fc.id
                            )
                          : "") +
                        (widget?.filters?.filter_manufacturers
                          ? "&filter_manufacturers=" +
                            widget?.filters?.filter_manufacturers.map(
                              (fm) => fm.id
                            )
                          : "") +
                        (widget?.filters?.filter_sellers
                          ? "&filter_sellers=" +
                            widget?.filters?.filter_sellers.map((fs) => fs.id)
                          : "") +
                        (widget?.filters?.filter_options
                          ? "&filter_options=" +
                            widget?.filters?.filter_options.map((fo) => fo.id)
                          : "")
                      : "/" +
                        // ToSeoUrl(widget.title)
                        widget.title
                          .replace(/\s+&amp;\s+|\s+&gt;\s+/g, "-")
                          .replace(/\s+/g, "-")
                          .replaceAll("/", "-")
                          .replace("%", "") +
                        "/c=" +
                        widget.type_id
                  }
                  state={{
                    banner_image_id: "",
                    source_type: source_type,
                    source_type_id: source_type_id ? source_type_id : "",
                  }}
                >
                  <h1 className="font-bold text-xs border px-2 py-1 cursor-pointer hover:opacity-80">
                    VIEW ALL
                  </h1>
                </Link>
              )}
            </div>
          )}
        </div>
      ) : (
        <div></div>
      )}

      {/* text */}
      {widget.type === "text" && (
        <div
          className="w-full"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(widget.html_content),
          }}
        />
      )}
      {/* Slider  */}
      {widget.display === "slider" &&
        widget.type !== "text" &&
        (widget.items.length > 1 ? (
          <div className="-mx-4 ">
            {width > 650 ? (
              <Swiper
                slidesPerView={1}
                autoplay={true}
                loop={true}
                pagination={{ clickable: true }}
                navigation={{
                  nextEl: swiperNavNextRef.current,
                  prevEl: swiperNavPrevRef.current,
                }}
                modules={[Pagination, Navigation, Autoplay]}
                className="sliderSwiper"
              >
                {widget.items.map((item, index) => {
                  return (
                    <SwiperSlide key={`sliderr` + index}>
                      {item.mobile_type_id === "0" ? (
                        item?.mobile_type == "6" ? (
                          <Link
                            data-index={index}
                            to={"/latest"}
                            state={{
                              banner_image_id: item.banner_image_id,
                              source_type: source_type,
                              source_type_id: source_type_id
                                ? source_type_id
                                : "",
                            }}
                            key={Math.random()}
                            onClick={() => {
                              if (
                                types[item.mobile_type]?.slice(0, 1) === "p"
                              ) {
                                setProductHolder({});
                              }
                            }}
                          >
                            <img
                              alt={item?.name}
                              src={item.image_url}
                              className="w-full"
                              height={item.banner_height}
                              placeholdersrc={SliderPlaceholder}
                            />
                          </Link>
                        ) : (
                          <div data-index={index} key={`slider` + index}>
                            <img
                              alt={item?.name}
                              src={item.image_url}
                              className="w-full"
                              height={item.banner_height}
                              placeholdersrc={SliderPlaceholder}
                            />
                          </div>
                        )
                      ) : (
                        <Link
                          data-index={index}
                          to={
                            // accountState.admin

                            //   ? `${path}/${types[item.mobile_type]}/${
                            //       item.mobile_type_id
                            //     }`
                            //   :
                            item?.name?.length > 0
                              ? "/" +
                                item?.name
                                  .replace(/\s+&amp;\s+|\s+&gt;\s+/g, "-")
                                  .replace(/\s+/g, "-")
                                  .replaceAll("/", "-")
                                  .replace("%", "") +
                                "/" +
                                types[item.mobile_type].slice(0, 1) +
                                "=" +
                                item.mobile_type_id
                              : "cat/c/" +
                                item.mobile_type_id +
                                (item.filters != false && "?has_filter=true") +
                                (item?.filters?.filter_categories
                                  ? "&filter_categories=" +
                                    item?.filters?.filter_categories.map(
                                      (fc) => fc.id
                                    )
                                  : "") +
                                (item?.filters?.filter_manufacturers
                                  ? "&filter_manufacturers=" +
                                    item?.filters?.filter_manufacturers.map(
                                      (fm) => fm.id
                                    )
                                  : "") +
                                (item?.filters?.filter_sellers
                                  ? "&filter_sellers=" +
                                    item?.filters?.filter_sellers.map(
                                      (fs) => fs.id
                                    )
                                  : "") +
                                (item?.filters?.filter_options
                                  ? "&filter_options=" +
                                    item?.filters?.filter_options.map(
                                      (fs) => fs.id
                                    )
                                  : "")
                          }
                          state={{
                            banner_image_id: item.banner_image_id,
                            source_type: source_type,
                            source_type_id: source_type_id
                              ? source_type_id
                              : "",
                          }}
                          // accountState.admin
                          //   ? `${path}/${types[item.mobile_type]}/${
                          //       item.mobile_type_id
                          //     }`
                          //   :

                          key={Math.random()}
                          onClick={() => {
                            if (types[item.mobile_type]?.slice(0, 1) === "p") {
                              setProductHolder({});
                            }
                          }}
                        >
                          <img
                            alt={item?.name}
                            src={item.image_url}
                            className="w-full"
                            height={item.banner_height}
                            placeholdersrc={SliderPlaceholder}
                          />
                        </Link>
                      )}
                    </SwiperSlide>
                  );
                })}
               
               
              </Swiper>
            ) : (
              <Slider {...settingM}>
                {widget.items.map((item, index) =>
                  item.mobile_type_id === "0" ? (
                    <div data-index={index} key={`sliderM` + index}>
                      <LazyLoadImage
                        alt={item?.name}
                        src={item.image_url}
                        className="w-full"
                        height={item.banner_height}
                        placeholdersrc={SliderPlaceholder}
                      />
                    </div>
                  ) : (
                    <Link
                      data-index={index}
                      to={
                        // accountState.admin

                        //   ? `${path}/${types[item.mobile_type]}/${
                        //       item.mobile_type_id
                        //     }`
                        //   :
                        item?.name?.length > 0
                          ? "/" +
                            item?.name
                              .replace(/\s+&amp;\s+|\s+&gt;\s+/g, "-")
                              .replace(/\s+/g, "-")
                              .replaceAll("/", "-")
                              .replace("%", "") +
                            "/" +
                            types[item.mobile_type].slice(0, 1) +
                            "=" +
                            item.mobile_type_id
                          : "cat/c/" +
                            item.mobile_type_id +
                            (item.filters != false && "?has_filter=true") +
                            (item?.filters?.filter_categories
                              ? "&filter_categories=" +
                                item?.filters?.filter_categories.map(
                                  (fc) => fc.id
                                )
                              : "") +
                            (item?.filters?.filter_manufacturers
                              ? "&filter_manufacturers=" +
                                item?.filters?.filter_manufacturers.map(
                                  (fm) => fm.id
                                )
                              : "") +
                            (item?.filters?.filter_sellers
                              ? "&filter_sellers=" +
                                item?.filters?.filter_sellers.map((fs) => fs.id)
                              : "") +
                            (item?.filters?.filter_options
                              ? "&filter_options=" +
                                item?.filters?.filter_options.map((fs) => fs.id)
                              : "")
                      }
                      state={{
                        banner_image_id: item.banner_image_id,
                        source_type: source_type,
                        source_type_id: source_type_id ? source_type_id : "",
                      }}
                      key={Math.random()}
                    >
                      <LazyLoadImage
                        alt={item?.name}
                        src={item.image_url}
                        className="w-full"
                        height={item.banner_height}
                        PlaceholderSrc={SliderPlaceholder}
                      />
                    </Link>
                  )
                )}
              </Slider>
            )}
          </div>
        ) : (
          <div className="flex  justify-between">
            {widget.items.map((item) => {
              const bool = widget.items.length > 1;

              return item.mobile_type_id !== "0" ? (
                <div
                  className={`p-1 ${
                    true && "w-full"
                  } cursor-pointer flex justify-center hover:opacity-80 w-1/${
                    widget.column_number
                  } md:w-1/${widget.column_number}`}
                  key={item.banner_image_id}
                >
                  <Link
                    to={
                      // accountState.admin && item?.filters == false

                      item?.name?.length > 0
                        ? "/" +
                          item?.name
                            .replace(/\s+&amp;\s+|\s+&gt;\s+/g, "-")
                            .replace(/\s+/g, "-")
                            .replaceAll("/", "-")
                            .replace("%", "") +
                          "/" +
                          types[item.mobile_type].slice(0, 1) +
                          "=" +
                          item.mobile_type_id
                        : "cat/c/" +
                          item.mobile_type_id +
                          (item.filters != false && "?has_filter=true") +
                          (item?.filters?.filter_categories
                            ? "&filter_categories=" +
                              item?.filters?.filter_categories.map(
                                (fc) => fc.id
                              )
                            : "") +
                          (item?.filters?.filter_manufacturers
                            ? "&filter_manufacturers=" +
                              item?.filters?.filter_manufacturers.map(
                                (fm) => fm.id
                              )
                            : "") +
                          (item?.filters?.filter_sellers
                            ? "&filter_sellers=" +
                              item?.filters?.filter_sellers.map((fs) => fs.id)
                            : "") +
                          (item?.filters?.filter_options
                            ? "&filter_options=" +
                              item?.filters?.filter_options.map((fs) => fs.id)
                            : "")
                    }
                    state={{
                      banner_image_id: item.banner_image_id,
                      source_type: source_type,
                      source_type_id: source_type_id ? source_type_id : "",
                    }}
                    className="w-full"
                  >
                    <LazyLoadImage
                      alt={item?.name}
                      src={item.image_url}
                      width={item.banner_width}
                      height={item.banner_height}
                      title={item?.name
                        ?.replace(/\s+&amp;\s+|\s+&gt;\s+/g, "-")
                        ?.replace("%", "")
                        ?.replace(/\s+/g, "-")
                        ?.replaceAll("/", "-")}
                      placeholdersrc={ProductPlaceholder}
                      className={`${true && "w-full"}`}
                    />
                  </Link>
                </div>
              ) : (
                <div
                  className={`p-1 w-full hover:opacity-80 w-1/${widget.column_number} md:w-1/${widget.column_number}`}
                  key={item.banner_image_id}
                >
                  <div>
                    <LazyLoadImage
                      alt={item?.name}
                      src={item.image_url}
                      width={item.banner_width}
                      height={item.banner_height}
                      title={item?.name
                        .replace(/\s+&amp;\s+|\s+&gt;\s+/g, "-")
                        .replace("%", "")
                        .replace(/\s+/g, "-")
                        .replaceAll("/", "-")}
                      placeholdersrc={ProductPlaceholder}
                      className={"w-full"}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        ))}
      {/* Grid */}
      {/* style={{backgroundColor:widget.background_color}} */}
      {widget.display === "grid" &&
        // widget.type !== "text" &&
        widget.column_number < 7 && (
          <div className="flex -mx-4 flex-wrap justify-between">
            {widget.items.map((item) => {
              const bool = widget.items.length > 0;

              return item.mobile_type_id !== "0" ? (
                <div
                  className={`${
                    !bool && "w-full"
                  } cursor-pointer flex justify-center hover:opacity-80 w-1/${
                    widget.column_number
                  } md:w-1/${widget.column_number}`}
                  key={item.banner_image_id}
                  style={{ padding: "1px" }}
                >
                  <Link
                    to={
                      item?.name?.length > 0 && item?.filters != false
                        ? "/" +
                          item?.name
                            ?.replace(/\s+&amp;\s+|\s+&gt;\s+/g, "-")
                            .replace("%", "")
                            .replace(/\s+/g, "-")
                            .replaceAll("/", "-") +
                          "/" +
                          types[item.mobile_type]?.slice(0, 1) +
                          "=" +
                          item.mobile_type_id +
                          "?has_filter=true" +
                          (item?.filters?.filter_categories
                            ? "&filter_categories=" +
                              item?.filters?.filter_categories.map(
                                (fc) => fc.id
                              )
                            : "") +
                          (item?.filters?.filter_manufacturers
                            ? "&filter_manufacturers=" +
                              item?.filters?.filter_manufacturers.map(
                                (fm) => fm.id
                              )
                            : "") +
                          (item?.filters?.filter_sellers
                            ? "&filter_sellers=" +
                              item?.filters?.filter_sellers.map((fs) => fs.id)
                            : "") +
                          (item?.filters?.filter_options
                            ? "&filter_options=" +
                              item?.filters?.filter_options.map((fo) => fo.id)
                            : "")
                        : item?.name?.length > 0
                        ? "/" +
                          item?.name
                            ?.replace(/\s+&amp;\s+|\s+&gt;\s+/g, "-")
                            .replace("%", "")
                            .replace(/\s+/g, "-")
                            .replaceAll("/", "-") +
                          "/" +
                          types[item.mobile_type]?.slice(0, 1) +
                          "=" +
                          item.mobile_type_id
                        : "cat/c/" + item.mobile_type_id
                    }
                    state={{
                      banner_image_id: item.banner_image_id,
                      source_type: source_type,
                      source_type_id: source_type_id ? source_type_id : "",
                    }}
                    onClick={() => {
                      if (types[item.mobile_type]?.slice(0, 1) === "p") {
                        setProductHolder(item);
                      }
                    }}
                  >
                    <img
                      alt={item?.name}
                      src={item.image_url}
                      width={item.banner_width}
                      height={item.banner_height}
                      title={item?.name
                        .replace(/\s+&amp;\s+|\s+&gt;\s+/g, "-")
                        .replace("%", "")
                        .replace(/\s+/g, "-")
                        .replaceAll("/", "-")}
                      placeholdersrc={ProductPlaceholder}
                      className={`${!bool && "w-full"}`}
                    />
                  </Link>
                </div>
              ) : // ) : item.mobile_type === "6" ? (
              //   <div
              //     className={` w-full hover:opacity-80 w-1/${widget.column_number} md:w-1/${widget.column_number}`}
              //     key={item.banner_image_id}
              //   >
              //     <Link to={"/latest"}>
              //       <LazyLoadImage
              //         alt={item?.name}
              //         src={`${window.config["site-url"]}/image/` + item.image}
              //         width={item.banner_width}
              //         height={item.banner_height}
              //         title={item?.name}
              //         placeholdersrc={ProductPlaceholder}
              //         className={"w-full"}
              //       />
              //     </Link>
              //   </div>
              item.mobile_type === "6" ? (
                <div
                  className={`hover:opacity-80 w-1/${widget.column_number} md:w-1/${widget.column_number}`}
                  key={item.banner_image_id}
                >
                  <Link
                    to={"/latest"}
                    state={{
                      banner_image_id: item.banner_image_id,
                      source_type: source_type,
                      source_type_id: source_type_id ? source_type_id : "",
                    }}
                  >
                    <img
                      alt={item?.name}
                      src={item.image_url}
                      width={item.banner_width}
                      height={item.banner_height}
                      title={item?.name}
                      placeholdersrc={ProductPlaceholder}
                      className={"w-full"}
                    />
                  </Link>
                </div>
              ) : (
                <div
                  className={`hover:opacity-80 w-1/${widget.column_number} md:w-1/${widget.column_number}`}
                  key={item.banner_image_id}
                >
                  <img
                    alt={item?.name}
                    src={item.image_url}
                    width={item?.banner_width}
                    height={item?.banner_height}
                    title={item?.name
                      .replace(/\s+&amp;\s+|\s+&gt;\s+/g, "-")
                      .replace("%", "")
                      .replace(/\s+/g, "-")
                      .replaceAll("/", "-")}
                    placeholdersrc={ProductPlaceholder}
                    className={`${!bool && "w-full"}`}
                  />
                </div>
              );
            })}
          </div>
        )}

      {widget.display === "grid" &&
        widget.type !== "text" &&
        widget.column_number > 6 && (
          <div className={`  grid grid-cols-${widget.column_number}`}>
            {widget.items.map((item) => {
              const bool = widget.items.length > 1;

              return item.mobile_type_id !== "0" ? (
                <div
                  className={`${!bool && "w-full"} cursor-pointer `}
                  key={item.banner_image_id}
                  style={{ padding: "1px" }}
                >
                  <Link
                    to={
                     
                      (item?.name?.length > 0
                        ? "/" +
                          item?.name
                            ?.replace(/\s+&amp;\s+|\s+&gt;\s+/g, "-")
                            .replace("%", "")
                            .replace(/\s+/g, "-")
                            .replaceAll("/", "-") +
                          "/" +
                          types[item.mobile_type]?.slice(0, 1) +
                          "=" +
                          item.mobile_type_id
                        : "cat/c/" + item.mobile_type_id) +
                      (item.filters
                        ? "?has_filter=true" +
                          (item?.filters?.filter_categories?.length
                            ? "&filter_categories=" +
                              item.filters.filter_categories.map((fc) => fc.id).join(",")
                            : "") +
                          (item?.filters?.filter_manufacturers?.length
                            ? "&filter_manufacturers=" +
                              item.filters.filter_manufacturers.map((fm) => fm.id).join(",")
                            : "") +
                          (item?.filters?.filter_sellers?.length
                            ? "&filter_sellers=" +
                              item.filters.filter_sellers.map((fs) => fs.id).join(",")
                            : "") +
                          (item?.filters?.filter_options?.length
                            ? "&filter_options=" +
                              item.filters.filter_options.map((fo) => fo.id).join(",")
                            : "")
                        : "")
                      
                      
                    }
                    state={{
                      banner_image_id: item.banner_image_id,
                      source_type: source_type,
                      source_type_id: source_type_id ? source_type_id : "",
                    }}
                    onClick={() => {
                      if (types[item.mobile_type]?.slice(0, 1) === "p") {
                        setProductHolder(item);
                      }
                    }}
                  >
                    <LazyLoadImage
                      alt={item?.name}
                      src={item.image_url}
                      width={item.banner_width}
                      height={item.banner_height}
                      title={item?.name
                        .replace(/\s+&amp;\s+|\s+&gt;\s+/g, "-")
                        .replace("%", "")
                        .replace(/\s+/g, "-")
                        .replaceAll("/", "-")}
                      placeholdersrc={ProductPlaceholder}
                      className={`${!bool && "w-full"}`}
                    />
                  </Link>
                </div>
              ) : // ) : item.mobile_type === "6" ? (
              //   <div
              //     className={` w-full hover:opacity-80 w-1/${widget.column_number} md:w-1/${widget.column_number}`}
              //     key={item.banner_image_id}
              //   >
              //     <Link to={"/latest"}>
              //       <LazyLoadImage
              //         alt={item?.name}
              //         src={`${window.config["site-url"]}/image/` + item.image}
              //         width={item.banner_width}
              //         height={item.banner_height}
              //         title={item?.name}
              //         placeholdersrc={ProductPlaceholder}
              //         className={"w-full"}
              //       />
              //     </Link>
              //   </div>
              item.mobile_type === "6" ? (
                <div
                  className={` w-full hover:opacity-80 w-1/${widget.column_number} md:w-1/${widget.column_number}`}
                  key={item.banner_image_id}
                >
                  <Link
                    to={"/latest"}
                    state={{
                      banner_image_id: item.banner_image_id,
                      source_type: source_type,
                      source_type_id: source_type_id ? source_type_id : "",
                    }}
                  >
                    <LazyLoadImage
                      alt={item?.name}
                      src={item.image_url}
                      width={item.banner_width}
                      height={item.banner_height}
                      title={item?.name}
                      placeholdersrc={ProductPlaceholder}
                      className={"w-full"}
                    />
                  </Link>
                </div>
              ) : (
                <div
                  className={`  hover:opacity-80 w-1/${widget.column_number} md:w-1/${widget.column_number}`}
                  key={item.banner_image_id}
                >
                  <div>
                    <LazyLoadImage
                      alt={item?.name}
                      src={item.image_url}
                      width={item.banner_width}
                      height={item.banner_height}
                      title={item?.name
                        ?.replace(/\s+&amp;\s+|\s+&gt;\s+/g, "-")
                        ?.replace(/\s+/g, "-")
                        ?.replaceAll("/", "-")}
                      placeholdersrc={ProductPlaceholder}
                      className={"w-full"}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        )}
      {/* Products Carousel */}
      {widget.display === "carousel" && widget.type !== "text" && (
        <div>
          {widget?.items?.length < 7 && width > 650 ? (
            <div className="flex">
              {width > 650 ? (
                <Slider
                  {...productSetting}
                  beforeChange={handleBeforeChange}
                  afterChange={handleAfterChange}
                >
                  {widget.items?.map((item) => {
                    if (item.product_id) {
                      return (
                        <div className="pr-2" key={item.product_id}>
                          <SingleProducts
                            isWidget={true}
                            likedData={likedData}
                            item={item}
                            click={handleOnItemClick}
                            dragging={dragging}
                          ></SingleProducts>
                        </div>
                      );
                    } else {
                      return (
                        <div
                          className={`p-1 cursor-pointer hover:opacity-80 w-1/${widget.column_number} md:w-1/${widget.column_number}`}
                          key={item.banner_image_id}
                        >
                          <Link
                            onClickCapture={handleOnItemClick}
                            to={
                              `${
                                item?.name?.length > 0
                                  ? "/" +
                                    item?.name
                                      ?.replace(/\s+&amp;\s+|\s+&gt;\s+/g, "-")
                                      ?.replace(/\s+/g, "-")
                                      ?.replaceAll("/", "-") +
                                    "/" +
                                    types[item.mobile_type].slice(0, 1) +
                                    "=" +
                                    item.mobile_type_id
                                  : "cat/c/" + item.mobile_type_id
                              }` +
                              (item?.filters != false && "?has_filter=true") +
                              (item?.filters?.filter_categories
                                ? "&filter_categories=" +
                                  item?.filters?.filter_categories.map(
                                    (fc) => fc.id
                                  )
                                : "") +
                              (item?.filters?.filter_manufacturers
                                ? "&filter_manufacturers=" +
                                  item?.filters?.filter_manufacturers.map(
                                    (fm) => fm.id
                                  )
                                : "") +
                              (item?.filters?.filter_sellers
                                ? "&filter_sellers=" +
                                  item?.filters?.filter_sellers.map(
                                    (fs) => fs.id
                                  )
                                : "") +
                              (item?.filters?.filter_options
                                ? "&filter_options=" +
                                  item?.filters?.filter_options.map(
                                    (fo) => fo.id
                                  )
                                : "")
                            }
                            state={{
                              banner_image_id: item.banner_image_id,
                              source_type: source_type,
                              source_type_id: source_type_id
                                ? source_type_id
                                : "",
                            }}
                          >
                            <img
                              alt={item?.name}
                              src={item.image_url}
                              width={item.banner_width}
                              height={item.banner_height}
                              title={item?.name
                                ?.replace(/\s+&amp;\s+|\s+&gt;\s+/g, "-")
                                ?.replace(/\s+/g, "-")
                                ?.replaceAll("/", "-")}
                              placeholdersrc={ProductPlaceholder}
                            />
                          </Link>
                        </div>
                      );
                    }
                  })}
                </Slider>
              ) : (
                <Slider {...productMobile}>
                  {widget.items?.map((item) => {
                    if (item.product_id) {
                      return (
                        <div className="pr-2" key={item.product_id}>
                          <SingleProducts
                            likedData={likedData}
                            item={item}
                          ></SingleProducts>
                        </div>
                      );
                    } else {
                      return (
                        <div className={`pr-2`} key={item.banner_image_id}>
                          <Link
                            to={
                              // accountState.admin

                              //   ? `${path}/${types[item.mobile_type]}/${
                              //       item.mobile_type_id
                              //     }`
                              //   :
                              item?.name?.length > 0
                                ? "/" +
                                  item?.name
                                    ?.replace(/\s+&amp;\s+|\s+&gt;\s+/g, "-")
                                    ?.replace(/\s+/g, "-")
                                    ?.replaceAll("/", "-")
                                    ?.replace("%", "") +
                                  "/" +
                                  types[item.mobile_type].slice(0, 1) +
                                  "=" +
                                  item.mobile_type_id
                                : "cat/c/" +
                                  item.mobile_type_id +
                                  (item.filters != false &&
                                    "?has_filter=true") +
                                  (item?.filters?.filter_categories
                                    ? "&filter_categories=" +
                                      item?.filters?.filter_categories.map(
                                        (fc) => fc.id
                                      )
                                    : "") +
                                  (item?.filters?.filter_manufacturers
                                    ? "&filter_manufacturers=" +
                                      item?.filters?.filter_manufacturers.map(
                                        (fm) => fm.id
                                      )
                                    : "") +
                                  (item?.filters?.filter_sellers
                                    ? "&filter_sellers=" +
                                      item?.filters?.filter_sellers.map(
                                        (fs) => fs.id
                                      )
                                    : "") +
                                  (item?.filters?.filter_options
                                    ? "&filter_options=" +
                                      item?.filters?.filter_options.map(
                                        (fs) => fs.id
                                      )
                                    : "")
                            }
                            state={{
                              banner_image_id: item.banner_image_id,
                              source_type: source_type,
                              source_type_id: source_type_id
                                ? source_type_id
                                : "",
                            }}
                          >
                            <LazyLoadImage
                              alt={item?.name}
                              src={item.image_url}
                              width={item.banner_width}
                              height={item.banner_height}
                              title={item?.name
                                ?.replace(/\s+&amp;\s+|\s+&gt;\s+/g, "-")
                                ?.replace(/\s+/g, "-")
                                ?.replaceAll("/", "-")}
                              placeholdersrc={ProductPlaceholder}
                            />
                          </Link>
                        </div>
                      );
                    }
                  })}
                </Slider>
              )}
            </div>
          ) : (
            <div className="">
              <div className="flex overflow-x-auto scrollbar-hide space-x-2">
                {widget.display === "carousel" &&
                  widget.type !== "text" &&
                  width < 650 &&
                  widget?.items[0]?.product_id &&
                  widget.items?.map((item) => {
                    return (
                      <div className="" key={item.product_id}>
                        <SingleProducts
                          scroll={true}
                          item={item}
                        ></SingleProducts>
                      </div>
                    );
                  })}
              </div>
              {width > 650 ? (
                <Slider
                  {...productSetting}
                  beforeChange={handleBeforeChange}
                  afterChange={handleAfterChange}
                >
                  {widget.items?.map((item) => {
                    if (item.product_id) {
                      return (
                        <div className="p-1" key={item.product_id}>
                          <SingleProducts
                            likedData={likedData}
                            item={item}
                            click={handleOnItemClick}
                            dragging={dragging}
                          ></SingleProducts>
                        </div>
                      );
                    } else {
                      return (
                        <div
                          className={`p-1 cursor-pointer hover:opacity-80 w-1/${widget.column_number} md:w-1/${widget.column_number}`}
                          key={item.banner_image_id}
                        >
                          <Link
                            onClickCapture={handleOnItemClick}
                            to={
                              // accountState.admin

                              //   ? `${path}/${types[item.mobile_type]}/${
                              //       item.mobile_type_id
                              //     }`
                              //   :
                              item?.name?.length > 0
                                ? //
                                  "/" +
                                  item?.name
                                    ?.replace(/\s+&amp;\s+|\s+&gt;\s+/g, "-")
                                    ?.replace(/\s+/g, "-")
                                    ?.replaceAll("/", "-")
                                    ?.replace("%", "") +
                                  "/" +
                                  types[item.mobile_type].slice(0, 1) +
                                  "=" +
                                  item.mobile_type_id
                                : "cat/c/" +
                                  item.mobile_type_id +
                                  (item.filters != false &&
                                    "?has_filter=true") +
                                  (item?.filters?.filter_categories
                                    ? "&filter_categories=" +
                                      item?.filters?.filter_categories.map(
                                        (fc) => fc.id
                                      )
                                    : "") +
                                  (item?.filters?.filter_manufacturers
                                    ? "&filter_manufacturers=" +
                                      item?.filters?.filter_manufacturers.map(
                                        (fm) => fm.id
                                      )
                                    : "") +
                                  (item?.filters?.filter_sellers
                                    ? "&filter_sellers=" +
                                      item?.filters?.filter_sellers.map(
                                        (fs) => fs.id
                                      )
                                    : "") +
                                  (item?.filters?.filter_options
                                    ? "&filter_options=" +
                                      item?.filters?.filter_options.map(
                                        (fs) => fs.id
                                      )
                                    : "")
                            }
                            state={{
                              banner_image_id: item.banner_image_id,
                              source_type: source_type,
                              source_type_id: source_type_id
                                ? source_type_id
                                : "",
                            }}
                          >
                            <LazyLoadImage
                              alt={item?.name}
                              src={item.image_url}
                              width={item.banner_width}
                              height={item.banner_height}
                              title={item?.name
                                ?.replace(/\s+&amp;\s+|\s+&gt;\s+/g, "-")
                                ?.replace(/\s+/g, "-")
                                ?.replaceAll("/", "-")}
                              placeholdersrc={ProductPlaceholder}
                            />
                          </Link>
                        </div>
                      );
                    }
                  })}
                </Slider>
              ) : (
                <div>
                  {/* <Slider {...productMobile}> */}
                  <div className={`flex overflow-x-auto pb-2`}>
                    {widget.items?.map((item) => {
                      if (item.product_id) {
                        return (
                          <div className="pr-2" key={item.product_id}>
                            {/* <SingleProducts
                              likedData={likedData}
                              item={item}
                            ></SingleProducts> */}
                          </div>
                        );
                      } else {
                        return (
                          <div
                            className={`pr-3 min-w-max`}
                            key={item.banner_image_id}
                          >
                            <Link
                              to={
                                // accountState.admin

                                //   ? `${path}/${types[item.mobile_type]}/${
                                //       item.mobile_type_id
                                //     }`
                                //   :
                                item?.name?.length > 0
                                  ? "/" +
                                    item?.name
                                      ?.replace(/\s+&amp;\s+|\s+&gt;\s+/g, "-")
                                      ?.replace(/\s+/g, "-")
                                      ?.replaceAll("/", "-")
                                      ?.replace("%", "") +
                                    "/" +
                                    types[item.mobile_type].slice(0, 1) +
                                    "=" +
                                    item.mobile_type_id
                                  : "cat/c/" +
                                    item.mobile_type_id +
                                    (item.filters != false &&
                                      "?has_filter=true") +
                                    (item?.filters?.filter_categories
                                      ? "&filter_categories=" +
                                        item?.filters?.filter_categories.map(
                                          (fc) => fc.id
                                        )
                                      : "") +
                                    (item?.filters?.filter_manufacturers
                                      ? "&filter_manufacturers=" +
                                        item?.filters?.filter_manufacturers.map(
                                          (fm) => fm.id
                                        )
                                      : "") +
                                    (item?.filters?.filter_sellers
                                      ? "&filter_sellers=" +
                                        item?.filters?.filter_sellers.map(
                                          (fs) => fs.id
                                        )
                                      : "") +
                                    (item?.filters?.filter_options
                                      ? "&filter_options=" +
                                        item?.filters?.filter_options.map(
                                          (fs) => fs.id
                                        )
                                      : "")
                              }
                              state={{
                                banner_image_id: item.banner_image_id,
                                source_type: source_type,
                                source_type_id: source_type_id
                                  ? source_type_id
                                  : "",
                              }}
                            >
                              <img
                                alt={item?.name}
                                src={item.image_url}
                                width={item.banner_width}
                                height={item.banner_height}
                                title={item?.name
                                  ?.replace(/\s+&amp;\s+|\s+&gt;\s+/g, "-")
                                  ?.replace(/\s+/g, "-")
                                  ?.replaceAll("/", "-")}
                                placeholdersrc={ProductPlaceholder}
                              />
                            </Link>
                          </div>
                        );
                      }
                    })}
                  </div>
                  {/* </Slider> */}
                </div>
              )}
            </div>
          )}{" "}
        </div>
      )}
    </div>
  );
}

export default WidgetsLoop;
